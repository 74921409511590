//1 top view
//2 big view
//3 bottom ruins
//4 front ruins
//5 bochkas
//6 fact
//7 river
//8 out
//9 boat
//10 river bot
//11 trees


import { toPercent } from '../../utils.js';

const position = [{
	height: 222,
width: 330,
left: 37,
top: 63,
},{
	height: 598,
width: 784,
left: 64,
top: 201,
},{
	height: 350,
width: 458,
left: 35,
top: 668,
},{
	height: 331,
width: 436,
left: 595,
top: 653,
},{
	height: 240.549,
	width: 330,
	left: 939,
	top: 43,
},{
	height: 248.60,
width: 330,
left: 1130,
top: 171,
},{
	height: 248.58,
width: 330,
left: 1249,
top: 43,
},{
	height: 316.044769,
width: 330,
left: 1559,
top: 42,
},{
	height: 436,
width: 660,
left: 1197,
top: 347
},{
	height: 241.6594,
width: 330,
left: 1110,
top: 808,
},{
	height: 192,
width: 259,
left: 1657,
top: 669,
},{
	height: 1013,
width: 706,
left: 385,
top: 43,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));