//1 sport left light
//2 sport circle
//3 draw
//4 scheme
//5 up game
//6 out
//7 scheme loc
//8 book vid
//9 dra vid
//10 pic vid
//111 bemch vid


import { toPercent } from '../../utils.js';

const position = [{
	height: 669,
width: 950,
left: 485,
top: 206,
},{
	height: 376,
	width: 462,
	left: 729,
	top: 674,
},{
	height: 1018,
width: 409,
left: 208,
top: 32,
},{
	height: 1018,
width: 409,
left: 1280,
top: 32,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));