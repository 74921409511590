//1 corridor
//2 view
//3 doggoz
//4 garden
//5 wom + kid
//6 out
//7 angle
//8 fam
//9 txt


import { toPercent } from '../../utils.js';

const position = [{
	height: 409,
width: 557,
left: 194,
top: 46,
},{
	height: 241,
	width: 410,
	left: 43,
	top: 337,
},{
	height: 557,
width: 759,
left: 174,
top: 464,
},{
	height: 221,
width: 349,
left: 527,
top: 122,
},{
	height: 378,
width: 285,
left: 740,
top: 534,
},{
	height: 558,
width: 385,
left: 1005,
top: 396,
},{
	height: 411,
width: 558,
left: 1172,
top: 163,
},{
	height: 558,
width: 374,
left: 1255,
top: 314,
},{
	height: 699,
width: 512,
left: 882,
top: 46,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));