import React from 'react';

import rv1 from '../../components/video/Ukraine/Beryslaw/1/RESEARCH/1.mp4'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'
import i7 from './img/7.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'

import t1 from './img/fsg-t1.svg'

import it1 from './img/fsg.svg'

import { getMobileResearch } from '../../utils';

import cityTitle from '../../img/titles/glogowek.svg'
import title from '../../img/titles/fsg.svg'

import positions from './research';

const img = [i1, i2, i3, i4, i5, i6, i7]

const researchImgItems = [r5, r2, r6, r1, r3, r4];

const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `fsg-research rii-${idx+1}`,
	type: 'image'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `fsg-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 3, text: it1}]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}

//const title = <div className="title synagogue-title"><div>Former</div> <div>Synagogue</div> <div>Głogówek</div></div>

//const cityTitle = <div className="synagogue-title title"><div>Głogówek</div></div>

export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		[], 
		researchImgItemsMap, 
		`r5, r2, r6, r1, r3, r4`, 
		`t1`, 
		null, 
		[`r4`, `t1`, `r3`, `r5`, `r6`, `r1`, `r2`]),
	title,
	cityTitle,
	route: "/poland/glogowek/former-synagogue-glogowek",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `fsg-${i}`,
		text: getText(i)
	}))	
}