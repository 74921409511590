/* eslint-disable no-restricted-globals */
import React, { useState, useRef } from 'react'

import { ReactComponent as CTW} from '../../img/ctw.svg'
//import openFullscreen from '../../fullscreen';


const Video = ({src, poster, css, isFullscreen, onLoadedData}) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const videoRef = useRef();

	const handleClick = event => {
		//if(isFullscreen){
		//	openFullscreen(event.target);
			
		//}
		isPlaying ? videoRef.current.pause() : videoRef.current.play();
		setIsPlaying(!isPlaying);
	}


	return <div className="video-item-wrapper"><video src={src} poster={poster} style={{...css}} onClick={handleClick} ref={videoRef} onLoadedData={onLoadedData}/>
		{!isPlaying && <CTW />}
	</div>
}

export default Video;