import React from 'react';

import rv1 from '../../components/video/Ukraine/Beryslaw/1/RESEARCH/1.mp4'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'

import it1 from './img/tsk/it1.svg'
import ii1 from './img/tsk/ii1.svg'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'
import r7 from './img/r7.png'

import t1 from './img/tsk-t1.svg'

import { getMobileResearch } from '../../utils'

import positions from './research'

import title from '../../img/titles/ts.svg'

const img = [i1, i2, i3, i4]

const researchImgItems = [r4, r7, r2, r3, r1, r5];
const researchTextItems = [t1]


const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `ts-research rii-${idx+1}`,
	type: 'image'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `ts-research research-text-item rti-${idx+1}`,
	type: 'text'
}))

const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 1, text: it1},
	{idx: 2, text: ii1},
]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}

//[r4, r2, r7, r3, r1, r5];

export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		[], 
		researchImgItemsMap, 
		`r4, r2, r7, r3, r1, r5`, 
		`t1`, 
		null, 
		[`r7`, `t1`, `r4`, `r2`, `r5`, `r3`, `r1`]),
	title,
	route: "/ukraine/kovel/train-station-kovel",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `ts-${i}`,
		text: getText(i)
	}))
}