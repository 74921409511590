//1 view
//2 letter
//3 port
//4 former forced txt
//5 txt


import { toPercent } from '../../utils.js';

const position = [{
	height: 568,
width: 886,
left: 26,
top: 70,
},{
	height: 625,
	width: 454,
	left: 1000,
	top: 396,
},{
	height: 369,
width: 278,
left: 1534,
top: 19,
},{
	height: 824,
width: 620,
left: 683,
top: 33,
},{
	height: 621,
width: 616,
left: 1196,
top: 358,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));