/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useRef, useState} from 'react';

import debounce from 'lodash/debounce';

import { Textfit } from 'react-textfit';

import ctw from '../../img/ctw.svg';
import plus from '../../img/plus.png';
import minus from '../../img/minus.png';
import up from '../../img/up.svg';
import down from '../../img/down.svg';

const ItemWithBorder  = ({c, item, hasLoaded, onItemClick}) => {
	const itemRef = useRef(null);
	const itemWrapperRef = useRef(null);
	const borderRef = useRef(null);
	const [borderParams, setBorderParams] = useState({width: 'auto', height: 'auto'})
	const [loaded, setLoaded] = useState(false);
	const [scale, setScale] = useState(1)
	const [itemStyle, setItemStyle] = useState({})
	const [borderStyle, setBorderStyle] = useState({})
	const [textReady, setTextReady] = useState(false);
	const [isSafari, setIsSafari] = useState(false)

	const { type } = item;

	const handleResize = () => {
		if(	!itemRef || !itemRef.current){ return }

		setBorderStyle({
			width: itemRef.current.clientWidth + 40, 
			height: item.allowScroll ? scaleParam(item.style.height, scale) : itemRef.current.clientHeight + 40, 
			top: item.style.top,
			left: item.style.left
		})
	}

	useEffect(() => {
		window.addEventListener('resize', debounce(handleResize, 500))

		setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		if(	!itemRef.current || 
			(itemRef.current.nodeName === 'IMG' && !loaded) ||
			(itemRef.current.nodeName === 'VIDEO' && !loaded)
		){ return }

		setItemStyle({
			height: isSafari ? 'unset' : `calc(${ scaleParam(item.style.height, scale)} - 40px)`, 
			top: `calc(${item.style.top} + 20px)` , 
			left: `calc(${item.style.left} + 20px)`,
			overflow: item.allowScroll ? 'scroll' : 'hidden',
			background: item.allowScroll ? '#c4c4c4' : 'unset'
		})

	}, [loaded])

	useEffect(() => {
		if(!itemStyle.top){ return }

		setBorderStyle({
			width: itemRef.current.clientWidth + 40, 
			height: item.allowScroll ? scaleParam(item.style.height, scale) : itemRef.current.clientHeight + 40, 
			top: item.style.top,
			left: item.style.left
		})

	}, [itemStyle])

	useEffect(() => {
		if(!itemStyle.top){ return; }
			setItemStyle({
				height: isSafari ? 'unset' : `calc(${ scaleParam(item.style.height, scale)} - 40px)`, 
				width: `calc(${ scaleParam(item.style.width, scale)} - 40px)`, 
				top: `calc(${item.style.top} + 20px)` , 
				left: `calc(${item.style.left} + 20px)`,
				overflow: item.allowScroll ? 'scroll' : 'hidden',
				background: item.allowScroll ? '#c4c4c4' : 'unset'
			})
	}, [scale])



	const scaleParam = (param, scale) => {
		if(param === 'auto'){ return }

		if(typeof param === 'number'){
			return param * scale
		}

		return param.includes('%') ? `${param.split('%')[0] * scale}%` : param
	}
	
	const resize = (type, event) => {
		event.preventDefault();
		event.stopPropagation();

		const nextScale = type === 'add' ? scale + 0.1 : scale - 0.1

		setScale(nextScale)
	}

	const applyLoaded = () => {
		setLoaded(true)
		hasLoaded(true)
	}

	const scrollText = (up) => {
		const px = up ? 100 : -100;
		itemRef.current.parentNode.scrollBy(0, px);
	}


	return <>
			<div className={`${item.cl} ${c} border`} style={borderStyle} ref={borderRef} onClick={onItemClick}>
			{item.allowScroll && <div className="sizer-wrapper allowed-scroll" >
					<img className="sizer-control" src={plus} onClick={(event) => resize('add', event)}/>
					<img className="sizer-control" src={minus} onClick={(event) => resize('remove', event)}/>
					{item.allowScroll && 
						<>
						<img className="sizer-control" src={up} onClick={() => scrollText(false)}/>
						<img className="sizer-control" src={down} onClick={() => scrollText(true)}/>
						</>
						}	
				</div>}
			</div>
			<div className={`${item.cl} ${c} i`} ref={itemWrapperRef} style={itemStyle}
			>
			{!item.allowScroll && <div className="sizer-wrapper">
					<img className="sizer-control" src={plus} onClick={(event) => resize('add', event)}/>
					<img className="sizer-control" src={minus} onClick={(event) => resize('remove', event)}/>
				</div>}
				{type === 'video' && <img src={ctw} className="ctw"/>}
				{type === 'image' && <img src={item.src} ref={itemRef} onLoad={() => applyLoaded()} style={isSafari ? {width: '100%', height: 'auto'} : {height: '100%'}}/>}
				{type === 'video' && <video src={item.src} poster={item.poster} ref={itemRef} onLoadedData={() => applyLoaded()} style={isSafari ? {width: '100%', height: 'auto'} : {height: '100%'}} muted/>}
				{type === 'text' && 
					<img 
						src={item.text} 
						ref={itemRef} 
						onLoad={() => applyLoaded()} 
						style={isSafari || item.allowScroll ? {width: '100%', height: 'auto'} : {height: '100%'}}/>}
			</div>
		</>
}

export default ItemWithBorder;