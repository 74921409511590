//1 sport left light
//2 isaac
//3 nobel
//4 a model
//5 nodelprize 1917

import { toPercent } from '../../utils.js';

const position = [{
	height: 826,
width: 538,
left: 485,
top: 57,
},{
	height: 336,
width: 849,
left: 142,
top: 57,
},{
	height: 378,
width: 479,
left: 142,
top: 371,
},{
	height: 291,
width: 472,
left: 1191,
top: 583,
},{
	height: 316,
width: 849,
left: 991,
top: 312,
},]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));