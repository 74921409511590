//1 blamb doc 
//2 geo
//3 triangle
//4 book 
//5 white sky park
//6 triangle park
//7 park w person
//8 park top
//9 grass
//10 man
//11 horse
//12 hz what
//13 workers
//14 worker
//15 park plan h
//16 plan v

import { toPercent } from '../../utils.js';

const position = 
	[{width: 253,
		height: 353,
		left: 114,
		top: 152,},
	{
		width: 254,
		height: 353,
		left: 193,
		top: 219,
	},
	{
		width: 250,
		height: 183,
		left: 287,
		top: 745,
	},
	{
		width: 338,
		height: 183,
		left: 39,
		top: 862,
	},{
		width: 264,
		height: 184,
		left: 447,
		top: 832,
	},{
		width: 337,
		height: 183,
		left: 621,
		top: 774,
	},{
		width: 339,
		height: 183,
		left: 868,
		top: 803,
	},{
		width: 339,
		height: 184,
		left: 1117,
		top: 715,
	},{
		width: 346,
		height: 242,
		left: 559,
		top: 65,
	},{
		width: 347,
		height: 242,
		left: 744,
		top: 124,
	},{
		width: 348,
		height: 242,
		left: 930,
		top: 182,
	},{
		width: 347,
		height: 242,
		left: 1117,
		top: 241,
	},{
		width: 346,
		height: 242,
		left: 1303,
		top: 300,
	},{
		width: 347,
		height: 242,
		left: 1488,
		top: 358,
	},{
		width: 392,
		height: 289,
		left: 1443,
		top: 42,
	},{
		width: 242,
		height: 291,
		left: 1593,
		top: 130,
	},{
		height: 499,
width: 854,
left: 478,
top: 291,
	},{
		height: 833,
		width: 610,
		left: 22,
		top: 19,
	},]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));