import React from 'react';

import rv1 from './video/1.mp4'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'
import i7 from './img/7.png'

import it2 from '../church/img/church/it2.svg'


import r1 from './img/r1.png'
import r2 from './img/r2.png'

import t1 from './img/fc-t1.svg'

import title from '../../img/titles/college.svg'

import positions from './research';

import {getMobileResearch} from '../../utils';


const img = [i1, i2, i3, i4, i5, i6, i7]

const researchImgItems = [r1];
const researchVideoItems = [{src: rv1, poster: r2}];
const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `fi-research rii-${idx+1}`,
	type: 'image'
}))

const researchVideoItemsMap = researchVideoItems.map(({src, poster}, idx) => ({
	src,
	poster,
	cl: `fi-research rvi-${idx+1}`,
	type: 'video'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `fi-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchImgItemsMap, ...researchVideoItemsMap,...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]

const infoTexts = [
	{idx: 2, text: it2
}]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}


export default {
	research,
	mobileResearch: 
	getMobileResearch(
		researchTextItemsMap, 
		researchVideoItemsMap, 
		researchImgItemsMap, 
		`r1`, 
		`t1`, 
		`v1`, 
		[`r1`, `t1`, `v1`]),
	title,
	route: "/ukraine/sumy/college-of-food-industry",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `fi-${i}`,
		text: getText(i)
	}))
}