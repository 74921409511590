//1 zweinec
//2 gray
//3 ruins
//4 sochy the village txt
//5 to me
//6 nobel prize

import { toPercent } from '../../utils.js';

const position = [{
	height: 453,
	width: 610,
	left: 736,
	top: 467.5,
},{
	height: 1075,
	width: 386,
	left: 1277,
	top: 3,
	},{
		height: 957,
		width: 386,
		left: 1500,
		top: 33,		
},{
	height: 647,
width: 1024,
left: 234,
top: 40,
},]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));