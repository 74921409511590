import React from 'react';

import rv1 from './video/1.mp4'
import rv2 from './video/2.mp4'

import mg1 from './img/1.png'
import mg2 from './img/2.png'
import mg3 from './img/3.png'
import mg4 from './img/4.png'
import mg5 from './img/5.png'
import mg6 from './img/6.png'
import mg7 from './img/7.png'
import mg8 from './img/8.png'
import mg9 from './img/9.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'
import r7 from './img/r7.png'

import ii5 from './img/mg/5.svg'
import ii8 from './img/mg/8.svg'
import ii2 from './img/mg/2.svg'

import t1 from './img/mg-t1.svg'

import title from '../../img/titles/mg.svg'

import positions from './research';

const mgImgs = [mg1, mg2, mg3, mg4, mg5, mg6, mg7, mg8, mg9]

const researchImgItems = [r1,r2,r3,r4,r5];
const researchVideoItems = [{src: rv1, poster: r7}, {src: rv2, poster: r6}];
const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `mg-research rii-${idx+1}`,
	type: 'image'
}))

const researchVideoItemsMap = researchVideoItems.map(({src, poster}, idx) => ({
	src,
	poster,
	cl: `mg-research rvi-${idx+1}`,
	type: 'video'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `mg-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research =  [...researchImgItemsMap, ...researchVideoItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 1, text: ii2},
	{idx: 4, text: ii5},
	{idx: 7, text: ii8}
]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}


export default {
	research,
	mobileResearch: [
		researchImgItemsMap[2],
		researchTextItemsMap[0],
		researchVideoItemsMap[0],
		researchImgItemsMap[4],
		researchImgItemsMap[3],
		researchImgItemsMap[1],
		researchImgItemsMap[0],
		researchVideoItems[1]
	],	
	title,
	route: "/ukraine/beryslaw/mass-graves",
	gallery: mgImgs.map((img, i) => ({
		link: img,
		cl: `mg-${i}`,
		text: getText(i)
	}))
}