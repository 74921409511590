import React from 'react';

import rv1 from '../../components/video/Ukraine/Beryslaw/1/RESEARCH/1.mp4'

import i0 from './img/0.png'
import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'
import i7 from './img/7.png'
import i8 from './img/8.png'

import t1 from './img/nc-t1.svg'

import positions from './research';

import title from '../../img/titles/nc.svg'

const img = [i0, i1, i2, i3, i4, i5, i6, i7, i8]


//const researchImgItems = [r1,r2,r3,r4,r5,r6,r7];
//const researchVideoItems = [{src: rv1, poster: r7}];
const researchTextItems = [t1]

//const researchImgItemsMap = researchImgItems.map((src, idx) => ({
//	src,
//	cl: `upk-research rii-${idx+1}`,
//	type: 'image'
//}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `upk-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]



export default {
	research,
	title,
	route: "/poland/bilgoraj/new-cemetry",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `cemetry-${i}`
	}))
}