import React from 'react';

import Video from '../video/Video';
import ComposedTextItem from '../composed-text/ComposedTextItem'

import logos from '../../img/logos-about.svg';
import pl from '../../img/photinus-logo.svg';
import pl1 from '../../img/photinus-logo-1.svg';

const About  = ({aboutData}) => {
	const {description, items} = aboutData;

	return <div className="about-page">
		<div id="about-wrapper"></div>
		<div className="content-wrapper">
			{items.map(({type, content}) => 
				type === 'video' && 
				<div className="video-wrapper">
					<div>
					<Video src={content.src} poster={content.poster} css={{width: '100%'}} isFullscreen={true}/>
					</div>
				</div>)}
		
			{items.map(({type, content, height}) => type === 'students' && <ComposedTextItem text={content} />)}
			{items.map(({type, content, height}) => type === 'project' && <ComposedTextItem text={content} height={height}/>)}
		<div className="about-logos" style={{height: '300px'}}>
			<ComposedTextItem text={<img src={logos} alt="logos" style={{width: '100%'}}/>}/>
		</div>
		<div className="about-devs" style={{height: '300px'}}>
			<ComposedTextItem
			height={'140px'}
			fit={true}
			text={
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '140px'}}>
					<img src={pl} />
					<img src={pl1} />
				</div>
			} />
		</div>
		</div>
		<div className="description"> {description}</div>
		<div className="description-box">{description}</div>
	</div>
	
}

export default About;