//1 note
//2 people
//3 building
//4 panorama
//5 txt



import { toPercent } from '../../utils.js';

const position = [{
	height: 896,
width: 655,
left: 78,
top: 28,
},{
	height: 480,
	width: 346,
	left: 19,
	top: 411,
},{
	height: 326,
width: 505,
left: 273,
top: 275,
},{
	height: 370,
width: 1610,
left: 273,
top: 640,
},{
	height: 677,
width: 706,
left: 795,
top: 22,
},{
	height: 677,
width: 419,
left: 1481,
top: 215,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));