import React from 'react';

import rv1 from './video/1.mp4'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'
import r7 from './img/r7.png'
import r8 from './img/r8.png'
import r9 from './img/r9.png'

import t1 from './img/hoc-t1.svg'

import it1 from './img/hoc.svg'

import in1 from '../bakhiv/img/bakhiv.svg'

import positions from './research';

import title from '../../img/titles/hoc.svg'

const hocImgs = [i1, i2, i3, i4, i5]

const researchImgItems = [r1,r2, r7, r8, r3, r4, r5, r6];

const researchVideoItems = [{src: rv1, poster: r9}];

const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `hoc-research rii-${idx+1}`,
	type: 'image'
}))

const researchVideoItemsMap = researchVideoItems.map(({src, poster}, idx) => ({
	src,
	poster,
	cl: `hoc-research rvi-${idx+1}`,
	type: 'video'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `hoc-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchImgItemsMap,  ...researchVideoItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 0, text: in1},
	{idx: 3, text: it1}
]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}

//[r1,r2, r7, r8, r3, r4, r5, r6];
export default {
	research,
	mobileResearch: [
		researchImgItemsMap[5],
		researchTextItemsMap[0],
		researchImgItemsMap[3],
		researchVideoItemsMap[0],
		researchImgItemsMap[4],
		researchImgItemsMap[6],
		researchImgItemsMap[7],
		researchImgItemsMap[2],
		researchImgItemsMap[1],
		researchImgItemsMap[0]
	],
	title,
	route: "/ukraine/kovel/house-of-culture",
	gallery: hocImgs.map((img, i) => ({
		link: img,
		cl: `hoc-${i}`,
		text: getText(i)
	}))
}