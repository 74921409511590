// 1. babula vert
// 2. company
// 3. babula hor
// 4. mom w kid
// 5. white pic
// 6. gray pic
// 7. big bid
// 8. car vid
// 9. horiz text
// 10. big text

import { toPercent } from '../../utils.js';

const position = [{
	width: 426,
	height: 661,
	left: 18,
	top: 18,
}, {
	height: 361,
	width: 498,
	left: 1388,
	top: 207,
},{
	height: 427,
	width: 582,
	left: 562,
	top: 11,
}, {
	width: 415,
	height: 336,
	left: 213,
	top: 413,
}, {
	width: 383,
	height: 282,
	left: 18,
	top: 636,
}, {
	height: 274,
	width: 349,
	left: 1059,
	top: 550
},{
	width: 1101,
	height: 643,
	left: 381,
	top: 272,
},{
	width: 554,
	height: 414,
	left: 406,
	top: 595,
}, {
	width: 847,
	height: 289,
	left: 870,
	top: 771,
}, {
	width: 692,
	height: 1032,
	left: 1124,
	top: 10,
},{
	height: 962,
	width: 590,
	left: 424,
	top: 68,
}]


const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));

