import React from 'react';

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'
import r7 from './img/r7.png'
import r8 from './img/r8.png'
import r9 from './img/r9.png'
import r10 from './img/r10.png'
import r11 from './img/r11.png'

import t1 from './img/faf-t1.svg'

import positions from './research';

import title from '../../img/titles/faf.svg'

import { getMobileResearch } from '../../utils'


const img = [i1, i2, i3, i4, i5]

const researchImgItems = [r7, r11, r5, r6, r1, r2, r3, r9, r10, r8, r4];

const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `faf-research rii-${idx+1}`,
	type: 'image'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `faf-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]



export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		[], 
		researchImgItemsMap, 
		`r7, r11, r5, r6, r1, r2, r3, r9, r10, r8, r4`, 
		`t1`, 
		null, 
		[`t1`, `r11`, `r10`, `r7`, `r1`, `r2`, `r4`, `r3`, `r8`, `r9`, `r5`, `r6`]),
	title,
	route: "/germany/berlin/former-aeg-factory",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `faf-${i}`,
	}))
}