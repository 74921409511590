import React from 'react';

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'

import rv1 from './video/1.mp4'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'
import r7 from './img/r7.png'
import r8 from './img/r8.png'
import r9 from './img/r9.png'
import r10 from './img/r10.png'
import r11 from './img/r11.png'
import r12 from './img/r12.png'
import r13 from './img/r13.png'
import r14 from './img/r14.png'
import r15 from './img/r15.png'
import r16 from './img/r16.png'
import r17 from './img/r17.png'

import t1 from './img/bakhiv-t1.svg'

import in1 from './img/bakhiv.svg'

import title from '../../img/titles/bakhiv.svg'

import positions from './research';

import { getMobileResearch } from '../../utils';

const img = [i1, i2, i3]

const researchVideoItems = [{src: rv1, poster: r17}];

const researchImgItems = [r1, r2, r7, r3, r8, r4, r5, r6, r9, r10, r11, r12, r13, r14, r15, r16];
//const researchVideoItems = [{src: rv1, poster: r7}];
const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `bakhiv-research rii-${idx+1}`,
	type: 'image'
}))

const researchVideoItemsMap = researchVideoItems.map(({src, poster}, idx) => ({
	src,
	poster,
	cl: `bakhiv-research rvi-${idx+1}`,
	type: 'video'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `bakhiv-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchImgItemsMap,...researchVideoItemsMap,...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [{}]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}


export default {
	research,
	mobileResearch: 
		getMobileResearch(
			researchTextItemsMap, 
			researchVideoItemsMap, 
			researchImgItemsMap, 
			`r1, r2, r7, r3, r8, r4, r5, r6, r9, r10, r11, r12, r13, r14, r15, r16`, 
		    `t1`, 
			`v1`, 
			[`v1`, `t1`, `r4`, `r5`, `r10`, `r12`, `r16`, `r15`, `r8`, `r7`, `r13`, `r14`, `r1`, `r6`, `r2`, `r9`, `r11`]),
	title,
	route: "/ukraine/kovel/bakhiv",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `bakhiv-${i}`,
		text: getText(i)
	}))
}