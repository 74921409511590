import React from 'react';

import rv1 from '../../components/video/Ukraine/Beryslaw/1/RESEARCH/1.mp4'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'
import i7 from './img/7.png'
import i8 from './img/8.png'
import i9 from './img/9.png'
import i10 from './img/10.png'

import it1 from './img/muranow/it1.svg'
import it2 from './img/muranow/it2.svg'
import it3 from './img/muranow/it3.svg'
import it4 from './img/muranow/it4.svg'
import it5 from './img/muranow/it5.svg'
import it6 from './img/muranow/it6.svg'
import it7 from './img/muranow/it7.svg'
import it8 from './img/muranow/it8.svg'
import it9 from './img/muranow/it9.svg'
import it10 from './img/muranow/it10.svg'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'

import t1 from './img/t1.svg'
import t2 from './img/t2.svg'

import title from '../../img/titles/muranow.svg'
import cityTitle from '../../img/titles/warsaw.svg'

import positions from './research';

import { getMobileResearch } from '../../utils'


const img = [i1, i2, i3, i4, i5, i6, i7, i8, i9, i10]

const researchImgItems = [r1, r4, r3, r2];


const researchTextItems = [t1, t2]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `m-research rii-${idx+1}`,
	type: 'image'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `m-research research-text-item rti-${idx+1}`,
	type: 'text'
}))




const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 0, text: it10}, 
	{idx: 1, text: it9},
	{idx: 2, text: it8},
	{idx: 3, text: it1}, 
	{idx: 4, text: it3},
	{idx: 5, text: it2},
	{idx: 6, text: it5}, 
	{idx: 7, text: it7},
	{idx: 8, text: it4},
	{idx: 9, text: it6}
]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}


export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		[], 
		researchImgItemsMap, 
		`r1, r4, r3, r2`, 
		`t1, t2`, 
		null, 
		[`t1`, `r2`, `r1`, `r4`, `r3`, `t2`]),
	cityTitle,
	title,
	route: "/poland/warsaw/muranow",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `m-${i}`,
		text: getText(i)
	}))
}