import React from 'react';

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'
import i7 from './img/7.png'
import i8 from './img/8.png'
import i9 from './img/9.png'
import i10 from './img/10.png'
import i11 from './img/11.png'
import i12 from './img/12.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'
import r7 from './img/r7.png'
import r8 from './img/r8.png'

import ii1 from './img/castle.svg'

import t1 from './img/castle-t1.svg'

import title from '../../img/titles/castle.svg'

import { getMobileResearch } from '../../utils'

import positions from './research';

const imgs = [i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, i11, i12]

const researchImgItems = [r2, r1, r6, r8, r7, r4, r5, r3];

const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `cg-research rii-${idx+1}`,
	type: 'image'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `cg-research research-text-item rti-${idx+1}`,
	type: 'text'
}))


const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 6, text: ii1}]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}

export default {
	research,
	mobileResearch: 
		getMobileResearch(
			researchTextItemsMap, 
			[], 
			researchImgItemsMap, 
			`r1, r3, r2, r5, r4`, 
		    `t1`, 
			null, 
			[`t1`, `r6`, `r1`, `r4`, `r7`, `r5`, `r3`, `r8`]),
	title,
	route: "/poland/glogowek/castle-glogowek",
	gallery: imgs.map((img, i) => ({
		link: img,
		cl: `cg-${i}`,
		text: getText(i)
	}))
}