/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import { ReactComponent as About} from '../../img/about.svg'
import { ReactComponent as Close} from '../../img/close.svg'
import { ReactComponent as  Info} from '../../img/info.svg'
import { ReactComponent as Research} from '../../img/research.svg'
import { ReactComponent as Navigation} from '../../img/navigation.svg'


const Controls  = ({showAbout, showInfo, showResearch, onCloseClick, showNavigation, disabled, isDevice, isInfoEnabled, isInfoPresent}) => {
	const [active, setActive] = useState(null)
	const [allowTrack, setAllowTrack] = useState(false);
	const [controlsOpacity, setControlsOpacity] = useState(1);

	const trackNavigation = event => {
		if(isDevice || isInfoEnabled || !allowTrack){ return; }
		event.stopPropagation()

		const m = event.clientX

		const { x, width } = event.target.getBoundingClientRect();

	
		const percentage = 1 - ((m-x) / (width / 100)) / 100;

		setControlsOpacity(percentage > 0.5 ? percentage : 0.5)
	  }

	const onClose = () => {
		setActive(null);
		onCloseClick();
	}

	const onSetControl = (cb, activeControl) => {
		if(disabled){ return }
		cb()
		setActive(activeControl);
	}

	const getOpacity = (type) => ((active && active !== type )|| (type === 'info' && !isInfoPresent)) ? 0 : controlsOpacity

	const getItemClass = type => 
		((active && active !== type )|| (type === 'info' && !isInfoPresent)) ?  'disabled-control' : 'active-control' 
		

	return isDevice ? <div className="mob-controls">
		<div>
			{active && active === 'navigation'  ? 
				<Close 
					onClick={onClose} className="close-contol" height="75px" width="75px" style={{pointerEvents: 'all'}}/> : 
				<Navigation 
					className={getItemClass('navigation')}
					onClick={() => onSetControl(showNavigation, 'navigation')} 
					height="75px" 
					width="75px"/>}
		</div>
		<div className="mob-controls-wrapper">
			{active && active === 'about' ? 
				<Close onClick={onClose} height="75px" width="75px"/> : 
				<About 
					className={getItemClass('about')}
					onClick={() => onSetControl(showAbout, 'about')} 
					style={{opacity: getOpacity('about')}}
					height="75px" width="75px"/>}
			<div>
			{<>{(active && active === 'info') ? 
				<Close onClick={onClose} height="75px" width="75px"/> : 
				<Info onClick={() => onSetControl(showInfo, 'info')} 
					className={getItemClass('info')} height="75px" width="75px"/>}</>}
			{active && active === 'research' ? 
				<Close onClick={onClose} height="75px" width="75px"/> : 
				<Research onClick={() => onSetControl(showResearch, 'research')} 
				className={getItemClass('research')} height="75px" width="75px"/>}
			</div>
		</div>
	</div> : <div className="controls" onMouseMove={trackNavigation} style={showResearch ? {pointerEvents: 'none'}: {}}onMouseEnter={() => setAllowTrack(true)} onMouseLeave={() => setAllowTrack(false)}>
		{active && active === 'about' ? 
			<Close onClick={onClose}/>  : 
			<About 
				onClick={() => onSetControl(showAbout, 'about')} 
				className={getItemClass('about')}
				style={{opacity: getOpacity('about')}}/>}
		{ <>{(active && active === 'info')  ? 
			<Close onClick={onClose}/>  : 
			<Info 
			onClick={() => onSetControl(showInfo, 'info')} 
			className={getItemClass('info')}
style={{opacity: getOpacity('info')}}/>}</>}
		{active && active === 'research' ? 
			<Close onClick={onClose}/> : 
			<Research 
			onClick={() => onSetControl(showResearch, 'research')} 
			className={getItemClass('research')}
			style={{opacity: getOpacity('research')}}/>}
		
	</div>
}

export default Controls;