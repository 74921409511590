/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const Screen2  = ({setEndIdx}) => {
	const onIntroClick = () => {
		setEndIdx(2);
	}
	 	 
	return  <div className={"intro"} onClick={onIntroClick}>
		<div>
			<div style={{marginBottom: '60px'}}>move the cursor up to navigate the exhibition</div>
			<div>buttons on the right to find more materials</div>
		</div>
		<div>
			<div style={{marginBottom: '60px'}}>scroll to watch the exhibition</div>
			<div>click anywhere to start</div>
		</div>
	</div>
}

export default Screen2;