/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useState, useRef, useEffect } from "react"

import { TransitionGroup, CSSTransition } from "react-transition-group"

import debounce from "lodash/debounce"
import { ReactComponent as Blur } from "../img/blur.svg"

import {
  Router,
  Switch,
  useHistory,
  Route,
  useLocation,
} from "react-router-dom"

import "./App.css"
import About from "./about/About"
import MobileAbout from "./mobile/about/MobileAbout"
import MobileResearch from "./mobile/research/MobileResearch"
import Research from "./research/Research"
import Navigation from "./navigation/Navigation"
import Controls from "./controls/Controls"
import Intro from "./intro/Intro"
import Location from "./location/Location"
import { locData } from "../data/links"
import { checkDevice } from "../utils"
import aboutData from "../data/about"
import PageLoader from "./page-loader/PageLoader"
;(function (mouseStopDelay) {
  var timeout
  document.addEventListener("mousemove", function (e) {
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      var event = new CustomEvent("mousestop", {
        detail: {
          clientX: e.clientX,
          clientY: e.clientY,
        },
        bubbles: true,
        cancelable: true,
      })
      e.target.dispatchEvent(event)
    }, mouseStopDelay)
  })
})(15000)

const App = () => {
  const locationsRef = useRef(null)
  const [isInfoEnabled, setIsInfoEnabled] = useState(false)
  const [displayLoader, setDisplayLoader] = useState(false)
  const [displayAbout, setDisplayAbout] = useState(false)
  const [displayNavigation, setDisplayNavigation] = useState(false)
  const [displayResearch, setDisplayResearch] = useState(false)
  const [displayIntro, setDisplayIntro] = useState(true)
  const [finished, setFinished] = useState(false)
  const [currentLocData, setCurrentLocData] = useState(locData[0])
  const [scrolledEnd, setScrolledEnd] = useState(false)
  const [currentLocIdx, setCurrentLocIdx] = useState(0)
  const [currentAbout, setCurrentAbout] = useState(null)
  const [countryIdx, setCountryIdx] = useState(null)
  const [px, setPx] = useState(0)
  const [isInfoPresent, setIsInfoPresent] = useState(true)

  const history = useHistory()
  let location = useLocation()

  const wrapperRef = useRef()

  const routes = locData.map(({ route }) => route)
  const navIdxs = [
    { country: "ukraine", idx: 0 },
    { country: "poland", idx: 11 },
    { country: "germany", idx: 20 },
  ]
  const isDevice = checkDevice()
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1

  const showOverlay = () => {
    document.querySelector("#blur-wrapper").classList.remove("show-stop")
  }

  const hideOverlay = () => {
    document.querySelector("#blur-wrapper").classList.add("show-stop")
  }

  useEffect(() => {
    const r =
      routes.indexOf(location.pathname) > 0
        ? routes.indexOf(location.pathname)
        : 0

    setCurrentLocIdx(r)
    setCurrentLocData(locData[r])

    window.addEventListener("wheel", onWheel, { passive: false })

    document.querySelector("body").addEventListener("mousemove", showOverlay)
    document.querySelector("body").addEventListener("mousestop", hideOverlay)

    return () => {
      window.removeEventListener("wheel", onWheel, { passive: false })
      document
        .querySelector("body")
        .removeEventListener("mousemove", showOverlay)
      document
        .querySelector("body")
        .removeEventListener("mousestop", hideOverlay)
    }
  }, [])

  useEffect(() => {
    const about = aboutData.find(
      (item) => item.city === locData[currentLocIdx].route.split("/")[2]
    )
    setCurrentAbout(about)
    const present =
      locData[currentLocIdx].gallery.filter((item) => item.text).length > 0

    setIsInfoPresent(present)
    setCurrentLocData(locData[currentLocIdx])
  
    history.push(routes[currentLocIdx])
  }, [currentLocIdx])

  let start = 0

  const trackScroll = () => {
    showOverlay()
    const target = document.querySelector(".locations-wrapper.current")
    if (
      isTitleVisible() ||
      isInfoEnabled ||
      displayAbout ||
      displayIntro ||
      displayResearch ||
      currentLocIdx === routes.length - 1 ||
      !target
    ) {
      return
    }

    const hasntScrolledToAnEnd =
      target.scrollWidth > target.clientWidth + target.scrollLeft

    console.log(hasntScrolledToAnEnd, target.scrollLeft, target.scrollWidth)

    if (target.scrollLeft <= 0 && currentLocIdx !== 0) {
      if (start === 0) {
        start++
        return
      }

      setCurrentLocIdx(currentLocIdx - 1)
      start = 0
    }

    if (hasntScrolledToAnEnd || target.scrollLeft === 0) {
      return
    }

    console.log(hasntScrolledToAnEnd, target.scrollLeft)

    setCurrentLocIdx(currentLocIdx + 1)
  }

  const delayedTrack = debounce(trackScroll, 500)

  const navigateToCountry = (country) => {
    const idx = navIdxs.find((item) => item.country === country).idx
    history.push(routes[idx])

    document.querySelector(".location-title").style.display = "none"

    setCurrentLocIdx(idx)
  }

  const removeIntro = () => {
    setDisplayIntro(false)
  }

  const getId = (idx) => {
    const l = locData[idx].route.split("/")
    return l[l.length - 1]
  }

  const isTitleVisible = () => {
    const title = document.querySelector(
      ".locations-wrapper.current .location-title"
    )
    return !title || title.getBoundingClientRect().width !== 0
  }

  const onWheel = (e) => {
    console.log("in func")
    e.stopPropagation()
    showOverlay()

    const { deltaX, deltaY } = e

    const isWin = navigator.platform.startsWith("Win")

    if (
      deltaY === -0 ||
      deltaY === -1 ||
      (!isWin && Math.abs(deltaY) < 200) ||
      (isWin && Math.abs(deltaY) < 99) ||
      displayAbout ||
      displayResearch ||
      isInfoEnabled
    ) {
      return
    }

    if (deltaX === -0 || deltaX === -1) {
      if (isTitleVisible()) {
        console.log("scroll title")
        const title = document.querySelector(
          ".locations-wrapper.current .location-title"
        )

        isWin
          ? (title.scrollLeft += deltaY * 5)
          : (title.scrollLeft += deltaY)
        return
      } else {
        const l = document.querySelector(".locations-wrapper.current")
        isWin ? (l.scrollLeft += deltaY * 5) : (l.scrollLeft += deltaY)
      }
    }
  }

  const locationsList = locData.map(({ route, gallery }, idx) => (
    <Route path={route}>
      <Location
        setDisplayLoader={setDisplayLoader}
        scrolledEnd={scrolledEnd}
        key={`${route}-${idx}`}
        currentLocData={currentLocData}
        isDevice={isDevice}
        displayResearch={displayResearch}
        setFinished={setFinished}
        fix={displayAbout || (isInfoEnabled && displayLoader)}
        finished={finished}
        gallery={gallery}
        id={getId(idx)}
        isInfoEnabled={isInfoEnabled && idx === currentLocIdx}
        isCurrent={idx === currentLocIdx}
        isCountry={idx === countryIdx}
      />
    </Route>
  ))

  const list = (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={3000}>
        <Switch location={location}>{locationsList}</Switch>
      </CSSTransition>
    </TransitionGroup>
  )

  return (
    <>
      {displayIntro && (
        <Intro
          removeIntro={removeIntro}
          displayIntro={displayIntro}
          isSafari={isSafari}
        />
      )}
      {displayLoader && (displayResearch || isInfoEnabled) && <PageLoader />}
      <div
        style={{
          position: "absolute",
          top: "0",
          width: "100%",
          height: "100%",
          pointerEvents: "none",
          zIndex: 999,
          mixBlendMode: "overlay",
        }}
        className="stop"
        id="blur-wrapper"
      >
        <Blur
          style={{
            zIndex: 999,
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            mixBlendMode: "overlay",
          }}
        />
        <Blur
          style={{
            zIndex: 999,
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            mixBlendMode: "overlay",
          }}
        />
      </div>

      <div ref={locationsRef} onScroll={delayedTrack}>
        {!displayResearch && !displayAbout && !isInfoEnabled && (
          <div
            className="navigation-wrapper"
            onMouseEnter={() => setDisplayNavigation(true)}
            onMouseLeave={() => setDisplayNavigation(false)}
          >
            <Navigation
              isVisible={displayNavigation}
              navigateToCountry={navigateToCountry}
            />
          </div>
        )}
        <Controls
          isInfoPresent={isInfoPresent}
          isInfoEnabled={isInfoEnabled}
          isDevice={isDevice}
          showNavigation={() => setDisplayNavigation(true)}
          showAbout={() => setDisplayAbout(true)}
          showInfo={() => setIsInfoEnabled(true)}
          showResearch={() => setDisplayResearch(true)}
          isCloseShown={displayResearch || displayAbout || isInfoEnabled}
          isNavVisible={displayNavigation}
          disabled={displayIntro}
          onCloseClick={() => {
            setDisplayAbout(false)
            setIsInfoEnabled(false)
            setDisplayResearch(false)
            setDisplayNavigation(false)
          }}
        />
        {list}
        {displayAbout && (isDevice ? <div id="about-wrapper"></div> : <></>)}
        {displayAbout &&
          (isDevice ? (
            <MobileAbout aboutData={currentAbout} />
          ) : (
            <About aboutData={currentAbout} />
          ))}
        {displayResearch &&
          (isDevice ? (
            <MobileResearch items={currentLocData.mobileResearch} />
          ) : (
            <Research
              items={currentLocData.research}
              setDisplayLoader={setDisplayLoader}
            />
          ))}
      </div>
    </>
  )
}

export default App
