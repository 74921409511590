import React from 'react';

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'
import i7 from './img/7.png'

import it1 from './img/sochy/it1.svg'
import it2 from './img/sochy/it2.svg'
import it3 from './img/sochy/it3.svg'
import it4 from './img/sochy/it4.svg'
import it5 from './img/sochy/it5.svg'
import it6 from './img/sochy/it6.svg'
import it7 from './img/sochy/it7.svg'


import r1 from './img/r2.png'

import t1 from './img/t1.svg'
import t2 from './img/t2.svg'
import t3 from './img/t3.svg'

import positions from './research';

import { getMobileResearch } from '../../utils'

import title from './img/Sochy.svg'

const img = [i1, i2, i3, i4, i5, i6, i7]

const researchImgItems = [r1];

const researchTextItems = [t1, t2, t3]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `s-research rii-${idx+1}`,
	type: 'image'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `s-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 0, text: it4}, 
	{idx: 1, text: it5},
	{idx: 2, text: it3},
	{idx: 3, text: it2},
	{idx: 4, text: it6},
	{idx: 5, text: it7},
	{idx: 6, text: it1},
]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}


export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		[], 
		researchImgItemsMap, 
		`r1`, 
		`t1, t2, t3`, 
		null, 
		[`r1`, `t1`, `t2`, `t3`]),
	title,
	route: "/poland/bilgoraj/sochy",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `s-${i}`,
		text: getText(i)
	}))
}