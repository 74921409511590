/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect } from 'react';

import {ReactComponent as Logos} from '../../img/logos-intro.svg'
import {ReactComponent as Blur} from '../../img/blur.svg'

import frame from '../../img/frame.png';


const Screen3  = ({removeIntro, isSafari, setEndIdx}) => {
	const logosRef = useRef();
	const logosBlurRef = useRef();
	const introRef = useRef();


	const applyClass = el => el.classList.add('hide')


	useEffect(() => {
		if(!logosBlurRef.current || !logosRef.current){ return; }

		const hideBlock = () => {
			logosRef.current && (
				applyClass(logosRef.current))
			document.querySelector('.intro') && applyClass(document.querySelector('.intro'))

			const moveNext = () => {
					setEndIdx(4);
					removeIntro(true)
					clearTimeout(moveNext);}
	
			
			setTimeout(moveNext, 3000);
		}

		logosBlurRef.current.addEventListener(isSafari ? "webkitTransitionEnd" : "transitionend", hideBlock);

		 return () => {
			logosBlurRef.current.addEventListener(isSafari ? "webkitTransitionEnd" : "transitionend", hideBlock);
		}
	}, [logosBlurRef.current])

	 
	 
	return  <div className="logo-wrapper" ref={logosRef} style={{zIndex: 15}}>
			<div className="logo-title" style={{zIndex: 15, position:'absolute'}}>
				<Logos style={{zIndex: 15}}/>
			</div>
			{/*<img className={"show-transition"} ref={logosBlurRef} src={blur}/>*/}
			<img style={{height: '100%', width: '100%',  mixBlendMode: 'difference'}} src={frame} className="bl" ref={logosBlurRef}  onLoad={e => e.target.classList.add('invert')}/>
			<div style={{height: '100%', width: '100%',position: 'absolute', top: 0, mixBlendMode: 'difference', willChange: 'fill',transform: `translateZ(0)`}}>
			</div>
			<div className="logo bygone"></div>
	</div>
}

export default Screen3;