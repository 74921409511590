/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import ItemWithBorder from '../item-with-border/ItemWithBorder';

const Research = ({items, setDisplayLoader}) => {

	const [isActive, setIsActive] = useState(false);
	const [item, setItem] = useState(null);
	const [zIdx, setZIdx] = useState(30);
	const [isVideoPlaying, setIsVideoPlaying] = useState(false);
	const [loadedItemsCount, setLoadedItemsCount] = useState(0);

	const [ms, setMs] = useState(0);

	useEffect(() => {
		setDisplayLoader(loadedItemsCount !== items.length)
	}, [loadedItemsCount])

	const dragStart = event => {
		if(	event.button !== 0 ||
			event.target.nodeName === 'path' || 
			event.target.nodeName.toLowerCase() === 'svg' || 
			!event.target.className.includes('i') || 
			!event.target.className.includes('border')){ return }

		if (event.target !== event.currentTarget) {
			setIsActive(true);
			setMs(new Date());
	
			setItem(event.target);
			let activeItem = event.target;

			const partner = getPartner(activeItem);

			partner.style.zIndex = zIdx + 1;
			activeItem.style.zIndex = zIdx + 1;
		
			setZIdx(zIdx + 1)
	
			if (activeItem !== null) {
				if (!activeItem.xOffset) {
				  activeItem.xOffset = 0;
				}
	  
				if (!activeItem.yOffset) {
				  activeItem.yOffset = 0;
				}
	  
				if (event.type === "touchstart") {
				  activeItem.initialX = event.touches[0].clientX - activeItem.xOffset;
				  activeItem.initialY = event.touches[0].clientY - activeItem.yOffset;
				} else {
				  activeItem.initialX = event.clientX - activeItem.xOffset;
				  activeItem.initialY = event.clientY - activeItem.yOffset;
				}
			  }
			}
	}

	const dragEnd = event => {
		if (item !== null) {
			item.initialX = item.currentX;
			item.initialY = item.currentY;
		  }

		setIsActive(false);
		setItem(null);

	}

	const drag = event => {
		if (!isActive) { return }
			if (event.type === "touchmove") {
			  event.preventDefault();
	
			  item.currentX = event.touches[0].clientX - item.initialX;
			  item.currentY = event.touches[0].clientY - item.initialY;
			} else {
			  item.currentX = event.clientX - item.initialX;
			  item.currentY = event.clientY - item.initialY;
			}
	
			item.xOffset = item.currentX;
			item.yOffset = item.currentY;
	
			translate(item.currentX, item.currentY, item);
	}

	const getPartner = el => {
		const selector = el.className.includes('border') ? 
			`.${el.className.replace("border", 'i')}` : 
			`.${el.className.replace("i", 'border')}`

		return document.querySelector(selector.split(' ').join('.'))
	}

	const translate = (xPos, yPos, el) => {
		const partner = getPartner(el);
		
		partner.style.transform = refreshTranslate("translate3d("+xPos+"px," + yPos + "px,0)", partner.style.transform);
		el.style.transform = refreshTranslate("translate3d(" + xPos + "px," + yPos + "px, 0)", el.style.transform);
	  }

	  const refreshTranslate = (translateValue, s) => {
		const transformStyles = s.split(')').filter(item => !item.includes('translate3d')).join(')')

		return `${transformStyles} ${translateValue}`
	}

	const onItemClick = event => {
		event.preventDefault();

		const downTime = new Date() - ms;

		setMs(0);

		if(!event.target.className.includes('rvi') || downTime > 300 || isActive){ return; }

		const item = getPartner(event.target)
		const video = item.querySelector('video');

		item.querySelector('.ctw').style.visibility = isVideoPlaying ? 'visible' : 'hidden'

		isVideoPlaying ? video.pause() : video.play();
		setIsVideoPlaying(!isVideoPlaying)
	}

	const hasLoaded = () => {
		setLoadedItemsCount(loadedItemsCount + 1);
	}


	return <>
		<div
		style={{position: 'absolute', top: 0, width: '100%', height: '100%'}}
		onTouchStart={dragStart} 
		onTouchEnd={dragEnd} 
		onTouchMove={drag} 
		onMouseDown={dragStart} 
		onMouseUp={dragEnd} 
		onMouseMove={drag}>
		{items.map((researchItem, idx) => 
			<ItemWithBorder item={researchItem} c={`stick-${idx}`} key={idx} hasLoaded={hasLoaded}
			onItemClick={onItemClick}
			/>)}
		</div>
		<div className={loadedItemsCount < items.length ? "research-wrapper loading" : "research-wrapper"}></div>
		</>
		
}

export default Research;
