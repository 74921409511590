import React from 'react';

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'

import t1 from './img/t1.svg'
import t2 from './img/t2.svg'

import rts from './img/rts.svg'

import title from '../../img/titles/rtg.svg'


import { getMobileResearch } from '../../utils';

import positions from './research';


const img = [i1, i2, i3, i4, i5]

const researchImgItems = [r1, r2];

const researchTextItems = [t1, t2]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `tsg-research rii-${idx+1}`,
	type: 'image'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `tsg-research research-text-item rti-${idx+1}`,
	type: 'text'
}))


const research = [...researchImgItemsMap,...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 1, text: rts}]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}

export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		[], 
		researchImgItemsMap, 
		`r1, r2`, 
		`t1, t2`, 
		null, 
		[`t1`, `r1`, `t2`, `r2`]),
	title,
	route: "/poland/glogowek/train-station",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `tsg-${i}`,
		text: getText(i)
	}))
}