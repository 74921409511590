import React from 'react';

import rv1 from './video/1.mp4'
import rv2 from './video/2.mp4'
import rv3 from './video/3.mp4'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'
import r7 from './img/r7.png'

import r8 from './img/r8.png'
import r9 from './img/r9.png'
import r10 from './img/r10.png'

import t1 from './img/church-t1.svg'

import it1 from './img/church/it1.svg'
import it2 from './img/church/it2.svg'
import it3 from './img/church/it3.svg'

import title from '../../img/titles/church.svg'
import cityTitle from '../../img/titles/sumy.svg'


import positions from './research';


const img = [i1, i2, i3, i4, i5, i6]

const researchImgItems = [r2, r3, r1, r6, r4, r5, r7];

const researchVideoItems = [{src: rv1, poster: r8}, {src: rv2, poster: r9}, {src: rv3, poster: r10}];

const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `ch-research rii-${idx+1}`,
	type: 'image'
}))

const researchVideoItemsMap = researchVideoItems.map(({src, poster}, idx) => ({
	src,
	poster,
	cl: `ch-research rvi-${idx+1}`,
	type: 'video'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `ch-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchImgItemsMap, ...researchVideoItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 1, text: it3}]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}

export default {
	research,
	title,
	cityTitle,
	route: "/ukraine/sumy/blessed-virgin-mary-church",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `ch-${i}`,
		text: getText(i)
	}))
}