import React from 'react';

import rv1 from '../../components/video/Ukraine/Beryslaw/1/RESEARCH/1.mp4'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'
import i7 from './img/7.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'

import ii1 from './img/kovelchanka/ii1.svg'
import it1 from './img/kovelchanka/it1.svg'
import it2 from './img/kovelchanka/it2.svg'

import t1 from './img/t1.svg'
import t2 from './img/t2.svg'
import t3 from './img/t3.svg'
import t4 from './img/t4.svg'

import title from '../../img/titles/kovelchanka.svg'
import cityTitle from '../../img/titles/kovel.svg'

import positions from './research';


const kovelchankaImgs = [i1, i2, i3, i4, i5, i6, i7]

const researchImgItems = [r2,r3, r1,r4];

const researchTextItems = [t1, t2, t3, t4]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `kovelchanka-research rii-${idx+1}`,
	type: 'image'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `kovelchanka-research research-text-item rti-${idx+1}`,
	type: 'text'
}))


const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 0, text: it1}, 
	{idx: 4, text: it2},
	{idx: 5, text: ii1
}]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}


export default {
	research,
	mobileResearch: [
		researchImgItemsMap[0],
		researchTextItemsMap[3],
		researchImgItemsMap[2],
		researchImgItemsMap[3],
		researchTextItemsMap[1],
		researchImgItemsMap[1],
		researchTextItemsMap[0],
		researchTextItemsMap[2],
	],
	cityTitle,
	title,
	route: "/ukraine/kovel/kovelchanka",
	gallery: kovelchankaImgs.map((img, i) => ({
		link: img,
		cl: `kovelchanka-${i}`,
		text: getText(i)
	}))
}