import React, { useEffect, useState, useRef } from 'react'

import { Textfit } from 'react-textfit';

const ComposedTextItem = ({text}) => {
	const [h, setH] = useState(0);
	const bgRef = useRef(null);

	useEffect(() => {
		if(!bgRef.current){ return }

		setH(bgRef.current.clientHeight)
	}, [bgRef])

	return <div style={{position: 'relative', height: h, marginTop: '20px'}}>
	<div className={`composed-text bg`} style={typeof text === 'string' ? {position: 'absolute', top: '0'} : {}} ref={bgRef}>
			<div style={{height: '100%', width: '100%', textTransform: 'uppercase', textAlign: 'justify', fontSize: '48px'}}>{text}</div>
		</div>
	<div className={`composed-text text`} style={typeof text === 'string' ? {position: 'absolute', top: '0'}  : {}}>
		<div style={{height: '100%', width: '100%', textTransform: 'uppercase', textAlign: 'justify', fontSize: '48px'}}>{text}</div>	
	</div>
	</div>
}

export default ComposedTextItem	