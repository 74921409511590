import React from 'react'

import l from './../../img/l.png'
import l1 from './../../img/l1.png'

const Spinner = () => 
<div className="spinner-wrapper">
	<img src={l1} className="spinner-bg"/>
	<img src={l} className="spinner-text"/>
</div>

export default Spinner;