import React from 'react';

import i1 from './img/1.png'
import mg8 from '../mass-graves-soldiers/img/3.jpeg'
import mg9 from '../mass-graves-soldiers/img/4.jpeg'



import r1 from './img/r1.png'
import empty from '../../img/empty.svg'
import t1 from './img/platz-t1.svg'

import title from '../../img/titles/platz.svg'

import positions from './research';


const img = [i1, mg8, mg9]
	
const researchImgItems = [r1];


const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `u-research rii-${idx+1}`,
	type: 'image'
}))

const researchTextItems = [t1]


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `u-research research-text-item rti-${idx+1}`,
	type: 'text'
}))

const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]

export default {
	research,
	mobileResearch: [...researchImgItemsMap, ...researchTextItemsMap],
	title,
	route: "/poland/warsaw/umschlagplatz",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `u-${i}`,
	}))
}