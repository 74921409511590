//1 gate
//2 white
//3 roof
//4 view
//5 text


import { toPercent } from '../../utils.js';

const position = [{
	height: 399,
	width: 389,
	left: 90,
	top: 436,
},{
	height: 819,
	width: 1142,
	left: 500,
	top: 155,
},{
	height: 397,
width: 399,
left: 1071,
top: 513,
},{
	height: 294,
width: 399,
left: 1380,
top: 95,
},{
	height: 918,
	width: 597,
	left: 155,
	top: 49,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));