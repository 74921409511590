import React from 'react';

import rv1 from '../../components/video/Ukraine/Beryslaw/1/RESEARCH/1.mp4'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'

import t1 from './img/sf-t1.svg'

import sf from './img/sf.svg'

import title from '../../img/titles/sf.svg'

import positions from './research';

const sfImgs = [i1, i2, i3, i4, i5, i6]

const researchImgItems = [r5, r3, r1, r6, r2, r4]

const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `sf-research rii-${idx+1}`,
	type: 'image'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `sf-research research-text-item rti-${idx+1}`,
	type: 'text'
}))




const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 2, text: sf
}]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}

export default {
	research,
	mobileResearch: [
		researchImgItemsMap[1],
		researchTextItemsMap[0],
		researchImgItemsMap[2],
		researchImgItemsMap[5],
		researchImgItemsMap[4],
		researchImgItemsMap[3],
		researchImgItemsMap[0]
	],
	title,
	route: "/ukraine/kovel/sewing-factory",
	gallery: sfImgs.map((img, i) => ({
		link: img,
		cl: `sf-${i}`,
		text: getText(i)
	}))
}