import React from 'react';

import rv1 from './video/1.mp4'

import ii1 from './img/kh/i1.svg'
import ii2 from './img/kh/i2.svg'

import it1 from '../church/img/church/it1.svg'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'	
import r5 from './img/r5.png'
import r6 from './img/r6.png'
import r7 from './img/r7.png'
import r8 from './img/r8.png'
import r9 from './img/r9.png'
import r10 from './img/r10.png'
import r11 from './img/r11.png'
import r12 from './img/r12.png'
import r13 from './img/r13.png'
import r14 from './img/r14.png'
import r15 from './img/r15.png'
import r16 from './img/r16.png'
import r17 from './img/r17.png'

import t1 from './img/t1.svg'
import t2 from './img/t2.svg'

import positions from './research';

import { getMobileResearch } from '../../utils'

import title from '../../img/titles/kh.svg'

const img = [i1, i2, i3, i4]

const researchImgItems = [r13, r16, r3, r1, r10, r11, r12, r5, r14, r8, r6, r4, r2, r15, r7];

const researchVideoItems = [{src: rv1, poster: r17}];
//const researchVideoItems = [{src: rv1, poster: r7}];
const researchTextItems = [t1, t2]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `kh-research rii-${idx+1}`,
	type: 'image'
}))

const researchVideoItemsMap = researchVideoItems.map(({src, poster}, idx) => ({
	src,
	poster,
	cl: `kh-research rvi-${idx+1}`,
	type: 'video'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `kh-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchImgItemsMap, ...researchVideoItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 0, text: ii1}, 
	{idx: 1, text: ii2},
	{idx: 3, text: it1}
]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}


export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		researchVideoItemsMap, 
		researchImgItemsMap, 
		`r13, r16, r3, r1, r10, r11, r12, r5, r14, r8, r6, r4, r2, r15, r7`, 
		`t1, t2`, 
		`v1`, 
		[`r11`, `t2`, `t1`, `r4`, `r3`, `r13`, `r12`, `r5`, `r14`, `r15`, `r6`, `r8`, `r1`, `r11`, `r17`, `r16`]),
	title,
	route: "/ukraine/sumy/kharytonenkos-estate",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `kh-${i}`,
		text: getText(i)
	}))
}