
//group left n r - 1
//group im center - r4
//plan - r5
//group heels - r2
//map - r3
//big v - r7
//smol v - r6
//text

import { toPercent } from '../../utils.js';

const position = 
	[{ height: 438, width: 636, left: 30, top: 35},{ height: 487, width: 707, left: 1221, top: 73}, { height: 428, width: 419, left: 761, top: 455}, 
		{ height: 427, width: 620, left: 580, top: 133}, { height: 315, width: 410, left: 848, top: 31}, { height: 408, width: 689, left: 1033,top: 617}, 
		{ height: 528, width: 907, left: 10, top: 495}, { height: 651, width: 437, left: 1, top: 312,}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));