import { toPercent } from '../../utils.js';

//1  im w ppl
//2  gray p
//3 map
// 4 drawing
// future gen
//6 diary
//7 dear kovelch
// 8 kovle

const position = [
{ height: 463,width: 691,left: 243,top: 55},
{ height: 353, width: 510, left: 21, top: 500},
{ height: 699, width: 620, left: 506, top: 359},
{ height: 359, width: 590, left: 1315, top: 376}, 

{height: 859,
	width: 686,
	left: 1034,
	top: 104,
	},{
	height: 296,
	width: 706,
	left: 1106,
	top: 745,
	},{
	height: 852,
	width: 706,
	left: 875,
	top: 189,
	},{
		height: 657,
width: 472,
left: 39,
top: 31,
	}]


const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));