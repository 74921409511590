/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect } from 'react';

import frame from '../../img/frame.png';



const Screen1  = ({removeIntro, isSafari, setEndIdx}) => {
	const blurRef = useRef();
	const logosBlurRef = useRef();


	const applyClass = el => el.classList.add('hide')

	useEffect(() => {
		const hideBlock = () => {
			applyClass(document.querySelector('#intro-screen'))
			const end = () => { setEndIdx(1); clearTimeout(end) }
			setTimeout(end, 2500);
		};

		document.querySelector('.bl').addEventListener(isSafari ? "webkitTransitionEnd" : "transitionend", hideBlock);
		

		 return () => {
			document.querySelector('.bl').removeEventListener(isSafari ? "webkitTransitionEnd" : "transitionend", hideBlock);
		}
	}, [])
	 
	return <div style={{height: '100%', width: '100%', zIndex: 14, position: 'absolute', top: 0, background: '#c4c4c4'}} id="intro-screen">
			<div className="bygone-title" style={{zIndex: 15}}>
				<div>BYGONE</div>
				<div>NEARBY</div>
			</div>
			<div style={{height: '100%', width: '100%', zIndex: 14, position: 'absolute', top: 0, mixBlendMode: 'difference', transform: `scale(1.5)`}}>
				<img ref={blurRef}  style={{height: '100%', width: '100%'}} src={frame} className="bl" ref={logosBlurRef}  onLoad={e => e.target.classList.add('invert')}/></div>
			<div className="title-bygone bygone" style={{zIndex: 13}}></div>
		</div>
}

export default Screen1;