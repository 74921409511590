//1 field
//2 meeting
//3 soldiers
//4 row
//5 angle
//txt


import { toPercent } from '../../utils.js';

const position = [{
	height: 546,
	width: 767,
	left: 183,
	top: 178,
},{
	height: 591,
	width: 604,
	left: 72,
	top: 421,
},{
	height: 734,
	width: 576,
	left: 820,
	top: 37,
},{
	height: 377,
	width: 377,
	left: 1174,
	top: 507,
},{
	height: 282,
	width: 433,
	left: 1445,
	top: 571,
},{
	height: 848,
	width: 468,
	left: 1432,
	top: 69,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));