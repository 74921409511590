import React from 'react';

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'

import ii5 from './img/i5.png'

import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'

import it1 from './img/barracks/it1.svg'
import ii1 from './img/barracks/ii1.svg'

import t1 from './img/barr-t1.svg'

import cityTitle from './img/gm.svg'
import title from './img/j.svg'

import {getMobileResearch} from '../../utils';

import positions from './research';

const img = [i1, i2, i3, i4, i5, i6]

const researchImgItems = [r1, r3, r2, r5, r4];

const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `b-research rii-${idx+1}`,
	type: 'image'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `b-research research-text-item rti-${idx+1}`,
	type: 'text'
}))

const infoTexts = [
	{idx: 2, text: it1},
	{idx: 4, text: ii1}]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}



const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


export default {
	research,
	mobileResearch: 
		getMobileResearch(
			researchTextItemsMap, 
			[], 
			researchImgItemsMap, 
			`r1, r3, r2, r5, r4`, 
		    `t1`, 
			null, 
			[`t1`, `r5`, `r4`, `r3`, `r1`, `r2`]),
	cityTitle,
	title,
	route: "/germany/marburg/barracks",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `b-${i}`,
		text: getText(i)
	}))
}
