import React from 'react'

import logos from '../../../img/logos-about.svg';
import pl from '../../../img/photinus-logo.svg';
import pl1 from '../../../img/photinus-logo-1.svg';

import Video from '../../video/Video';

const MobileAbout = ({aboutData}) => {
	const {description, items} = aboutData;

	return <div id="about">
	<div className="videos">
		
	{items.map(({type, content}) => 
			type === 'video' && <Video src={content.src} poster={content.poster} isFullscreen={true} css={{width: '100%', padding: '30px'}}/>)}
	</div>

	<div className="mob-about-wrapper">
		<div>{description}</div>
	</div>

	{items.map(({type, content}) => type !== 'video'  && 
		<div className="mob-about-wrapper">
			<div style={{fontSize: '6vh'}}>{content}</div>
		</div>)}

		<div style={{ width: '100%', zIndex: '111', padding: '0 30px'}}>
			<img src={logos} alt="logos" style={{width: '100%',  zIndex: '111',}}/>
		</div>

	<div style={{ width: '100%', zIndex: '111', padding: '30px'}}>
	<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', zIndex: '111'}}>
					<img src={pl} />
					<img src={pl1} />
				</div>
	</div>
</div>
}

export default MobileAbout;


{/*<div className="content-wrapper">
			{items.map(({type, content}) => 
				type === 'video' && 
				<div className="video-wrapper">
					<div>
					<Video src={content.src} poster={content.poster} css={{width: '100%'}} isFullscreen={true}/>
					</div>
				</div>)}
		<div className="about-students">
			{items.map(({type, content}) => type === 'students' && <ComposedTextItem text={content} />)}
		</div>
		<div className="about-project">
			{items.map(({type, content}) => type === 'project' && <ComposedTextItem text={content} />)}
		</div>
		<div className="about-logos" style={{height: '300px'}}>
			<ComposedTextItem text={<img src={logos} alt="logos" style={{width: '100%'}}/>} fit={true} />
		</div>
		<div className="about-devs" style={{height: '300px'}}>
			<ComposedTextItem
			fit={true}
			text={
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
					<img src={pl} />
					<img src={pl1} />
				</div>
			} />
		</div>
		</div>*/}