import { toPercent } from '../../utils.js';

//1 gray front
//2 perron
//3 front w ppl
//4 2pack
//5 left right
//6 front empty
//7 peerron fr ppl
//txt

const position = [{
	height: 471,
width: 677,
left: 32,
top: 149,
},{
	height: 402,
width: 694,
left: 70,
top: 658,
},{
	height: 661,
width: 523,
left: 428,
top: 198,
},{
	height: 212,
width: 313,
left: 1205,
top: 554,
},{
	height: 212,
width: 294,
left: 1518,
top: 90,
},{
	height: 220,
width: 278,
left: 1527,
top: 743,
},{
	height: 458,
width: 812,
left: 857,
top: 72,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));