/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import upkComplex from './upk'
import massGraves from './mass-graves'
import kovelchanka from './kovelchanka'
import massGravesGerman from './mass-graves-soldiers'
import sewingFactory from './sewing-factory'
import houseOfCulture from './house-of-culture'
import trainStationKovel from './train-station-k'
import bakhiv from './bakhiv'
import church from './church'
import kharytonenko from './kharytonenko'
import foodCollege from './college'
import newCemetry from './new-cemetry'
import singerHouse from './singer-house'
import oldCemetry from './old-cemetry'
import sochy from './sochy'
import muranow from './muranow'
import platz from './umschlagplatz'
import castle from './castle'
import synagogue from './former-synagogue'
import trainStationGlogowek from './train-station-g'
import forcedLabourCamp from './forced-labour'
import formerAegFactory from './former-aeg'
import barracks from './barracks'
import kaserne from './kaserne'

export const locData = [
	upkComplex, massGraves, kovelchanka, massGravesGerman, sewingFactory,
	houseOfCulture, trainStationKovel, bakhiv, church, kharytonenko, foodCollege,
	singerHouse, newCemetry, oldCemetry,sochy, muranow, platz, synagogue,
	trainStationGlogowek, castle, barracks, kaserne, forcedLabourCamp, formerAegFactory,  
]

