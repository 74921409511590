import React from 'react';

import rv1 from '../../components/video/Ukraine/Beryslaw/1/RESEARCH/1.mp4'

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'

import t1 from './img/t1.svg'
import t2 from './img/t2.svg'

import { getMobileResearch } from '../../utils';

import title from './img/oc.svg'

import positions from './research';


const img = [i1, i2, i3]

const researchImgItems = [r3, r2, r1, r4, r5];

const researchTextItems = [t1, t2]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `oc-research rii-${idx+1}`,
	type: 'image'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `oc-research research-text-item rti-${idx+1}`,
	type: 'text'
}))



const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		[], 
		researchImgItemsMap, 
		`r3, r2, r1, r4, r5`, 
		`t1, t2`, 
		null, 
		[`r1`, `r3`, `t2`, `r4`, `t1`]),
	title,
	route: "/poland/bilgoraj/old-cemetry",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `oc-${i}`
	}))
}