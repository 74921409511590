//1 sport left light
//2 sport circle
//3 draw
//4 scheme
//5 up game
//6 out
//7 scheme loc
//8 book vid
//9 dra vid
//10 pic vid
//111 bemch vid


import { toPercent } from '../../utils.js';

const position = [{
	height: 689,
width: 1087,
left: 483,
top: 111,
},{
	height: 1064,
width: 706,
left: 150,
top: 8,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));