//1 angle building
//2 view
//3 map
//4 plan
//5 people
//6 we have txt
//7 old jewish txt


import { toPercent } from '../../utils.js';

const position = [{
	height: 379,
width: 561,
left: 69,
top: 66,
},{
	height: 224,
	width: 283,
	left: 22,
	top: 563,
	
},{
	height: 873,
width: 1374,
left: 211,
top: 83,
},{
	height: 579,
width: 401,
left: 863,
top: 18,
},{
	height: 313,
width: 476,
left: 46,
top: 496,
},{
	height: 311,
width: 695,
left: 635,
top: 755,
},{
	height: 793,
width: 836,
left: 1043,
top: 127,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));