import React from 'react';

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'
import i5 from './img/5.png'
import i6 from './img/6.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'

import t2 from './img/t2.svg'
import t1 from './img/t1.svg'

import title from '../../img/titles/flc.svg'
import cityTitle from '../../img/titles/berlin.svg'

import positions from './research';

import { getMobileResearch } from '../../utils';


const img = [i1, i2, i3, i4, i5, i6]

const researchImgItems = [r1,r2,r3,];

const researchTextItems = [t1, t2]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `flc-research rii-${idx+1}`,
	type: 'image'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `flc-research research-text-item rti-${idx+1}`,
	type: 'text'
}))


const research = [...researchImgItemsMap,  ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		[], 
		researchImgItemsMap, 
		`r1, r2, r3`, 
		`t1, t2`, 
		null, 
		[`r3`, `t1`, `r2`, `t2`, `r1`]),
	cityTitle,
	title,
	route: "/germany/berlin/forced-labour-camp",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `flc-${i}`,
	}))
}