//1 paper plan
//2 stairs
//3 big plan
//4 top view
//5 inside
//6 out
//7 txt


import { toPercent } from '../../utils.js';

const position = [{
	height: 379,
width: 288,
left: 31,
top: 27,
},{
	height: 834,
	width: 681,
	left: 31,
	top: 160,
},{
	height: 462,
width: 643,
left: 391,
top: 67,
},{
	height: 361,
width: 626,
left: 1258,
top: 53,
},{
	height: 338,
	width: 504,
	left: 733,
	top: 656,
},{
	height: 424,
	width: 628,
	left: 1258,
	top: 570,
},{
	height: 396,
	width: 1161,
	left: 723,
	top: 239,
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));