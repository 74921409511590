import React from 'react';

import aboutBeryslawPoster from '../img/about-beryslaw-poster-1.png'
import aboutBerlinPoster from '../img/about-berlin-poster-1.png'
import aboutBilgorajPoster from '../img/about-bilgoraj-poster-1.png'
import aboutGlogowekPoster from '../img/about-glogowek-poster-1.png'
import aboutKovel1Poster from '../img/about-kovel-poster-1.png'
import aboutKovel2Poster from '../img/about-kovel-poster-2.png'
import aboutMarburgPoster from '../img/about-marburg-poster-1.png'
import aboutSumy1Poster from '../img/about-sumy-poster-1.png'
import aboutSumy2Poster from '../img/about-sumy-poster-2.png'
import aboutWarsawPoster from '../img/about-warsaw-poster-1.png'

import beryslaw from '../video/about-beryslaw.mp4';
import berlin from '../video/about-berlin.mp4';
import bilgoraj from '../video/about-bilgoraj.mp4';
import glogowek from '../video/about-glogowek.mp4';
import kovel1 from '../video/about-kovel-1.mp4';
import kovel2 from '../video/about-kovel-2.mp4';
import marburg from '../video/about-marburg.mp4';
import sumy1 from '../video/about-sumy-1.mp4';
import sumy2 from '../video/about-sumy-2.mp4';
import warsaw from '../video/about-warsaw.mp4';

const description = `Bygone Nearby is a collaborative project bringing together schools from across Poland, Germany and Ukraine under the topic of displacement. The sites visited under the scope of this project have all been greatly affected by World War II, which critically changed the towns and villages and resulted in entire populations being uprooted. The forced migrations depicted here are a fragment of the past, but the exchange between these three countries is ongoing and its effects far-reaching, yet so often hidden. 

Photography as a language falls short in making the atrocities of the war visible, but it does enable us to position ourselves in relation to them, and lets us feel and take notice. Focusing mainly on buildings which had been repurposed several times during, before and after the war, standing thus as contemporary witnesses, pupils were invited to investigate their hometowns' histories, using photography as a reflective tool.
The workshops which took place over the course of summer 2020 were developed on behalf of the Goethe-Institut Ukraine, under the creative direction of artist and photographer Jakob Ganslmeier, accompanied by a team of historians, teachers and experts. As 2020 took unexpected turns, we were challenged to bring the results to life in a digital form. Bygone Nearby thus became an extensive online platform offering not only the final outcomes, but additionally insight into the participatory processes that led to the photographs, including the questions raised by students and their written reflections on the topic.
This project is funded by the "Eastern Partnerships Programme" of the Federal Foreign Office in Germany.`

export default [
	{
		city: 'beryslaw',
		description,
		items: [
			{
				type: 'video',
				content: {
					src: beryslaw,
					poster: aboutBeryslawPoster
				}
			},
			{
				type: 'students',
				content: `Ukraine
				Beryslav
				
				Pupils:
				Ivan Muterko
				Anastasiya Yakusheva
				Liza Yuhnenko
				
				Teachers:
				Olga Gudym
				Natalia Kuznetsova
				School in Beryslav
				
				Artistic Director: Jakob Ganslmeier
				
				Historian: Nadiia Ufimtseva
				
				Videographer: Ana Zibelnik
				`
			},{
				height: '316px',
				type: 'project',
				content: `With special thanks 
				to The UPK (Uchbovo-Profesiyniy Kompleks, 
				the educational and professional complex)
				for the material provided.`
			}
		]	
	},
	{
		city: 'kovel',
		description,
		items: [
			{
				type: 'video',
				content: {
					src: kovel2,
					poster: aboutKovel1Poster
				}
			},
			{
				type: 'video',
				content: {
					src: kovel1,
					poster: aboutKovel2Poster
				}
			},
			{
				type: 'students',
				content: `Ukraine
				Kovel
				
				Pupils:
				Vladyslava Behal
				Kateryna Biruk
				Valentyna Bober
				Mariana Lastovyria
				Anastasiia Mishchuk
				Mariia Movchan
				
				Teachers:
				Viktoriia Harbuz
				Ruslana Kozachuk
				Ruslana Shvorak
				Olena Smetiukh
				
				Olena-Pchilka-Gymnasium in Kovel
				
				Artistic Director: Jakob Ganslmeier
				
				Historian: Nadiia Ufimtseva
				
				Videographer: Ana Zibelnik`},{
					height: '960px',
					type: 'project',
					content: `With many thanks to the Kovel Historical Museum, 
					Kovel city council,
					Myron Starko for the German research group 
					“West” and "Protecting Memory" project for 
					the material provided. 
					

					Special thanks to the Foundation Memorial to the 
					murdered Jews of Europe for the material provided of 
					the construction and protection works in Bakhiv (Research section).
					Photographs
					by Anna Voitenko 
					for Jewish Committee Berlin. 
					`
				}	
		]	
	},
	{
		city: 'sumy',
		description,
		items: [
			{
				type: 'video',
				content: {
					src: sumy2,
					poster: aboutSumy1Poster
				}
			},
			{
				type: 'video',
				content: {
					src: sumy1,
					poster: aboutSumy2Poster
				}
			},
			{
				type: 'students',
				content: `Ukraine
				Sumy
				
				Pupils:
				Arina Akentieva
				Valeriia Dashkina
				Anastasiia Korzhylova
				Anastasiia Latkovska
				
				Teacher:
				Olga Dashkina
				
				Gymnasium №1 in Sumy
				
				Artistic Director: Jakob Ganslmeier
				
				Historian:Nadiia Ufimtseva
				
				Videographer: Ana Zibelnik				
				`
			},{
				type: 'project',
				height: '483px',
				content: `With special thanks to Sumy Regional Museum, 
				Volodymyr Bykov and the Department 
				of Urban Planning and Architecture
				of Sumy Regional State Administration 
				and photographer Ihor Roychenko  
				for the material provided. `
			}
		]	
	},
	{
		city: 'bilgoraj',
		description,
		items: [
			{
				type: 'video',
				content: {
					src: bilgoraj,
					poster: aboutBilgorajPoster
				}
			},
			{
				type: 'students',
				content: `Poland
				Bilgoraj
				
				Pupils:
				Olivia Dudzic
				Agnieszka Popko
				Maja Zastawny
				
				Teachers:
				Ewa Spustek
				Alina Typko
				
				Szkoła Podstawowa nr 5 im.
				Księdza Jana Twardowskiego w Biłgoraju
				
				Artistic Director: Jakob Ganslmeier
				
				Historian: Piotr Przybyła, PhD				
				`
			},{
				height: '1518px',
				type: 'project',
				content: `Bilgoraj

				With special thanks to Starostwo 
				Powiatowe w Biłgoraju and 
				ExpressMap Polska 
				for the material provided.
				
				Synagogue/Singer House, 
				research section - cover:
				Ⓒ Isaac Bashevis Singer, published 
				by: Fawcett Crest Books, New York
				
				Old cemetery, research section – in order of appearance:
				1, 4, unknown author; public domain
				2,3 Ⓒ Starostwo Powiatowe w Biłgoraju
				
				Sochy, research section – in order of appearance:
				1, Ⓒ ExpressMap Polska, Warszawa
				2, unknown author; public domain
				`
			}
		]	
	},
	{
		city: 'warsaw',
		description,
		items: [
			{
				type: 'video',
				content: {
					src: warsaw,
					poster: aboutWarsawPoster
				}
			},
			{
				type: 'students',
				content: `Poland
				Warsaw
				
				Pupils:
				Anastasia Bashuk
				Olga Rybałt
				Helena Swirtun
				Mikołaj Wyjatek
				
				Teacher:
				Norbert Stüwe
				
				Willy-Brandt-Schule
				
				Artistic Director: Jakob Ganslmeier
				
				Historian: Piotr Przybyła, PhD
				
				Videographer: Ana Zibelnik				
				`
			},{
				height: '1351px',
				type: 'project',
				content: `Umschlagplatz, research section:
				unknown author; original publication: Warszawskie getto 1943−1988. W 45 rocznicę powstania, Warszawa 1988
				
				Muranow, research section – in order of appearance:
				1, original publication: Jürgen Stroop: 
					Żydowska dzielnica mieszkaniowa w Warszawie już nie istnieje!
					/Es gibt keinen jüdischen Wohnbezirk in Warschau mehr!, ed. 
					by Andrzej Żbikowski, Warszawa 2009.
				2, unknown author; original publication: Plan generalny Warszawy, Warszawa 1965.
				3, unknown author; original publication: “Stolica” 21/1951.
				4, by Zbyszko Siemaszko; original publication: Warszawa 1945-1970, Warszawa 1970. 
				`
			}
		]	
	},
	{
		city: 'glogowek',
		description,
		items: [
			{
				type: 'video',
				content: {
					src: glogowek,
					poster: aboutGlogowekPoster
				}
			},
			{
				type: 'students',
				content: `Poland
				Glogowek
				
				Pupils:
				Hanna Ćwięczek
				Maja Gerstenberg
				Aleksandra Poremba
				Szymon Reiwer
				
				Teacher:
				Sebastian Gerstenberg
				Aleksandra Zienkiewicz
				
				Szkoła Podstawowa im. Jana Pawła II w Racławicach Śląskich.
				Szkoła Podstawowa nr 2 im. H. Sienkiewicza w Głogówku.
				
				Artistic Director: Jakob Ganslmeier
				
				Historian: Piotr Przybyła, PhD				
				`},{
					height: '1968px',
					type: 'project',
					content: `Glogowek

					With special thanks to Józef Janeczko, Jarosław Kłuskiewicz and Sebastian Szkodziński for the mate-rial provided.
					
					Raclawice, research section - map:
					Original publication: Josef Pfeifer, 
					Deutsch-Rasselwitz im Wechsel der 
					Jahrhunderte. 
					Ortsgeschichte von 1252 bis 1945, 
					hrsg. von Josef Cyrus, Hehlen 1981.
					
					Synagogue, research section – in order of appearance:
					1, 2, Ⓒ Gminna Spółdzielnia „Samopomoc Chłopska” 
					3, by Sebastian Szkodziński
					4, Ⓒ Jarosław Kłuskiewicz
					5,6, unknown author; original publication: „Oberschlesien im Bild” 14/1930
					
					Castle, research section – in order of appearance:
					
					1, 3, 5, 6, 7, 8 Ⓒ Jarosław Kłuskiewicz
					2, unknown author; public domain
					4, by Sebastian Szkodziński
					`
				}
		]	
	},
	{
		city: 'marburg',
		description,
		items: [
			{
				type: 'video',
				content: {
					src: marburg,
					poster: aboutMarburgPoster
				}
			},
			{
				type: 'students',
				content: `Germany
				Marburg
				
				Pupils:
				Mathilde Conze
				Isabelle Kühnelt
				Justus Reiss
				Maren Sonja Schulz
				
				Teacher:
				Bernhard Rosenkötter
				Gymnasium Philippinum
				
				Artistic Director: Jakob Ganslmeier
				
				Historical advisor: Dominik Pick, PhD
				
				Videographer: Ana Zibelnik	
				`
			},{
				height: '883px',
				type: 'project',
				content: `With special 
				thanks to the Hessian Regional 
				History Information System (LAGIS) 
				and Germany’s documentation center 
				for art history  (Deutsches Dokumentationszentrum 
				für Kunstgeschichte) – 
				Bildarchiv Foto Marburg 
				for the material provided. 

				https://www.lagis-hessen.de/de
				/subjects/idrec/sn/bd/id/75-172
				https://www.lagis-hessen.de/de/
				subjects/idrec/sn/bd/id/75-197`
			}
		]	
	},
	{
		city: 'berlin',
		description,
		items: [
			{
				type: 'video',
				content: {
					src: berlin,
					poster: aboutBerlinPoster
				}
			},
			{
				type: 'students',
				content: `Germany
				Berlin
				
				Pupils:
				Josia Labes
				Laura Mühlfriedel
				Giulia Ortmann
				
				Teacher:
				Joanna Zaborowski
				
				Wilhelm-Bölsche-Oberschule
				
				Artistic Director: Jakob Ganslmeier 
				
				Historical advisor: Dominik Pick, PhD.
				
				Videographer: Ana Zibelnik
				`
			},
			{
				type: 'project',
				height: '1527px',
				content: `With special thanks 
				to the Nazi Forced Labour 
				Documentation Centre and 
				Stiftung Deutsches Technikmuseum f
				or the material provided.

				Forced Labour Camp Documentation Centre, 
				research section - in order of appearance:
				1, 2, 3, Ⓒ Nazi Forced Labour Camp Documentation Center
				
				Former AEG-Factory, 
				research section – in order of appearance:
				1, 2, 3, 4, 5, Ⓒ AEG-KWO-Archiv der Stiftung Deutsches Technikmuseum Berlin
				6, by Ulich Tempel for Nazi Forced Labour Camp Documentation Center
				7, Ⓒ Nazi Forced Labour Camp Documentation Center
				8, 9, 10, 11 Ⓒ AEG-KWO-Archiv der Stiftung Deutsches Technikmuseum Berlin
				`
			}
		]	
	}
]