/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef } from 'react';


//import FitText from '@kennethormandy/react-fittext'
import { Textfit } from 'react-textfit';


const LineItem = ({cl, link, text, isInfoEnabled, isDevice, idx, setFinished, setItemsLoadedCount, setLineItemsLoadedCount}) => {
const [textLoaded, setTextLoaded] = useState(false);
const [imgClipPath, setImgClipPath] = useState("polygon(15% 15%, 100% 0, 100% 100%, 0% 100%)" )
const clipTextRef = useRef(null);


const clipImg = (event) => {    
    event.preventDefault();
    event.stopPropagation();

    const {bottom, right, left } = event.target.getBoundingClientRect();

    const rightClip = (event.clientX - left) / ((right - left) / 100);
    const topClip = event.clientY / (bottom / 100); 

    setImgClipPath(`polygon(${rightClip}% ${topClip}%, 100% 0, 100% 100%, 0% 100%)`)
  }


const onTextReady = () => {
    setTextLoaded(true); 
}


return (isInfoEnabled && text) ? 
    <div className="line-item">
        <div className={`${cl} item-wrapper`} onMouseMove={clipImg} onMouseLeave={() => setImgClipPath("polygon(0 0, 100% 0, 100% 100%, 0% 100%)")}>
            <img async border="0" src={link} alt="b1" style={{clipPath: imgClipPath, WebkitClipPath: imgClipPath}}/>
        </div>
        <div className="clip-text" ref={clipTextRef}>
        {!text.includes('svg') ? 
                <Textfit 
                mode="multi" 
                style={{height: '100%', width: '100%', opacity: textLoaded ? 1 : 0, pointerEvents: 'none'}} 
                onReady={onTextReady}>{text}</Textfit> : <img src={text} alt={'info'} onLoad={setItemsLoadedCount}/>}
            </div>
	</div> : 
    <div className="line-item">
        <div className={cl}>
            <img async border="0" src={link} alt="b1" onLoad={setLineItemsLoadedCount}/>
        </div>
    </div>
}

export default LineItem;
