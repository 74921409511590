/* eslint-disable jsx-a11y/alt-text */
import React from 'react';


const MobileResearch = ({items}) => (<div className="mobile-research-wrapper">
			{items.map((item, idx) => (
				<div className="mobile-research-item">
				<div className={`${item.cl} border`} ></div>
				<div className={`${item.cl} i`} >
					{item.type === 'image' && <img src={item.src} />}
					{item.type === 'video' && <video src={item.src} poster={item.poster} />}
					{item.type === 'text' &&  <img src={item.text}/>}
				</div>
				</div>
			))}
		</div>)

export default MobileResearch;