/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'

import LineItem from '../line-item/LineItem';
import LocationTitle from '../location-title/LocationTitle';


const Location = ({currentLocData, isDevice, displayResearch, setFinished, fix, finished, gallery, isInfoEnabled, id, scrolledEnd, setDisplayLoader, isCountry, isCurrent}) => {
	const [itemsLoadedCount, setItemsLoadedCount] = useState(0);
	const [lineItemsLoadedCount, setLineItemsLoadedCount] = useState(0);

	useEffect(() => {
		if(!isInfoEnabled){ return; }

		if(itemsLoadedCount !== gallery.filter(item => item.text && item.text.includes('svg')).length){
			setDisplayLoader(true);
		}
	}, [isInfoEnabled])

	useEffect(() => {	
		if(!isInfoEnabled){ return; }
		
		if(itemsLoadedCount === gallery.filter(item => item.text && item.text.includes('svg')).length){
			setDisplayLoader(false);
			setItemsLoadedCount(0)
		}

	}, [itemsLoadedCount])
	

	const getLine = (items) => 
		(<div className="location" style={{zIndex: 3}} id={id}>
			{items.map(({link, cl, text}, idx) => (
					<LineItem 
						key={idx}
						setFinished={setFinished}
						cl={cl} 
						link={link} 
						text={text} 
						isInfoEnabled={isInfoEnabled} 
						isDevice={isDevice} 
						idx={idx}
						setItemsLoadedCount={() => setItemsLoadedCount(itemsLoadedCount + 1)}
						setLineItemsLoadedCount={() => setLineItemsLoadedCount(lineItemsLoadedCount + 1)}
						/>
		))}
		</div>);

	const showLocationTitle = !isInfoEnabled && isCurrent;

	const getLocationClass = () => {
		const current = isCurrent ? `locations-wrapper current` : `locations-wrapper`;

		return isCurrent ? `locations-wrapper current` : `locations-wrapper`
	}


	return <div className={isCurrent ? `locations-wrapper current` : `locations-wrapper`}>
			 <LocationTitle 
				locationTitle={currentLocData.title} 
				cityTitle={currentLocData.cityTitle} 
				countryTitle={currentLocData.countryTitle} 
				displayResearch={displayResearch}
				onLocationScrolled={() => setFinished(true)}
				finished={finished}
				/>
			
			{getLine(gallery)}
	</div>}

export default Location;