/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

const LocationTitle = ({countryTitle, displayResearch, cityTitle, locationTitle}) => {

	const getTitle = src => <div style={{minWidth: '100%', height: '100%', marginRight: '100px'}} className="title">
						<img src={src} style={{maxWidth: '100%', maxHeight: '100vh', pointerEvents: 'none'}} alt="title" className="show"/>
					</div>
	const locationsCount = () => {
		if(countryTitle){ return document.querySelector('.location-title').clientWidth * 3 }
		if(cityTitle){return document.querySelector('.location-title').clientWidth * 2 }
		return document.querySelector('.location-title').clientWidth
	}

	const onTitleScroll = e => {
		e.preventDefault();
		if(e.target.scrollLeft >= locationsCount()){ e.target.style.display = 'none' }
	}

	return displayResearch ? 
	<div className="location-title">{getTitle(locationTitle)}</div>:

				<div className="location-title" onScroll={onTitleScroll}>
					{countryTitle && getTitle(countryTitle)}
					{cityTitle && getTitle(cityTitle)}
					{getTitle(locationTitle)}
					<div style={{minWidth: '100%', height: '100%', marginRight: '50px', display: 'block'}} className="title">
					</div>
				</div>
}

export default LocationTitle;