import React from 'react';

import rv1 from './video/1.mp4'
import rv2 from './video/2.mp4'

import upk1 from './img/1.png'
import upk2 from './img/2.png'
import upk3 from './img/3.png'
import upk4 from './img/4.png'
import upk5 from './img/5.png'
import upk6 from './img/6.png'
import upk7 from './img/7.png'
import upk8 from './img/8.png'
import upk9 from './img/9.png'
import upk10 from './img/10.png'
import upk11 from './img/11.png'
import upk12 from './img/12.png'
import upk13 from './img/13.png'
import upk14 from './img/14.png'
import upk15 from './img/15.png'
import upk16 from './img/16.png'
import upk17 from './img/17.png'
import upk18 from './img/18.png'
import upk19 from './img/19.png'
import upk20 from './img/20.png'
import upk21 from './img/21.png'
import upk22 from './img/22.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'
import r5 from './img/r5.png'
import r6 from './img/r6.png'
import r7 from './img/r7.png'
import r8 from './img/r8.png'

import i2 from './img/upk/2.svg'
import i4 from './img/upk/4.svg'
import i5 from './img/upk/5.svg'
import i8 from './img/upk/8.svg'
import i9 from './img/upk/9.svg'

import cityTitle from './img/upk-title.svg'
import title from './img/upk.svg'

import t1 from './img/t1.svg'
import t2 from './img/t2.svg'
import t3 from './img/t3.svg'

import positions from './research';


const upkImgs = [upk1, upk2, upk3, upk4, upk5, upk16, upk21, upk6, upk7, upk8, upk9, upk10, upk11, upk12, upk13, upk14,upk15,upk17,upk18, upk19, upk20]

const researchImgItems = [r1,r2,r3,r4,r5,r6];
const researchVideoItems = [{src: rv2, poster: r7}, {src: rv1, poster: r8}];
const researchTextItems = [t1, t2]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `upk-research rii-${idx+1}`,
	type: 'image'
}))

const researchVideoItemsMap = researchVideoItems.map(({src, poster}, idx) => ({
	src,
	poster,
	cl: `upk-research rvi-${idx+1}`,
	type: 'video'
}))

const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `upk-research research-text-item rti-${idx+1}`,
	type: 'text'
}))

const bigtext = {
	text: t3,
	cl: `upk-research research-text-item rti-3`,
	type: 'text',
	allowScroll: true
};


const research = 
	[...researchImgItemsMap, 
	...researchVideoItemsMap, 
	...researchTextItemsMap, 
	bigtext].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


const infoTexts = [
	{idx: 1, text: i2},
	{idx: 3, text: i4},
	{idx: 4, text: i5},
	{idx: 7, text: i8},
	{idx: 8, text: i9}
]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}



export default {
	research,
	mobileResearch: [
		researchImgItemsMap[0],
		researchImgItemsMap[1], 
		researchTextItemsMap[0], 
		researchVideoItemsMap[0],
		researchImgItemsMap[3],
		researchTextItemsMap[1],
		researchImgItemsMap[4],
		researchImgItemsMap[5],
		researchImgItemsMap[2],
		researchVideoItemsMap[1],
		bigtext
	],
	route: "/ukraine/beryslaw/upk-complex",
	title,
	cityTitle,
	gallery: upkImgs.map((img, i) => ({
		link: img,
		cl: `upk-${i}`,
		text: getText(i)
	}))
}