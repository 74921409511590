//1 sport left light
//2 sport circle
//3 draw
//4 scheme
//5 up game
//6 out
//7 scheme loc
//8 book vid
//9 dra vid
//10 pic vid
//111 bemch vid


import { toPercent } from '../../utils.js';

const position = [{
	height: 383,
width: 554,
left: 98,
top: 29,
},{
	height: 383,
width: 554,
left: 354,
top: 69,
}, {
	height: 232,
width: 296,
left: 295,
top: 526,
},{
	height: 411,
width: 303,
left: 748,
top: 11,
},{
	height: 383,
width: 554,
left: 540,
top: 392,
},{
	height: 292,
width: 204,
left: 1035,
top: 496,
},{
	height: 487,
width: 356,
left: 1496,
top: 383,
}, {
	height: 331,
width: 558,
left: 55,
top: 695,
},{
	height: 331,
width: 558,
left: 645,
top: 695,
},{
	height: 331,
width: 558,
left: 1235,
top: 695,
},{
	height: 924,
width: 736,
left: 1128,
top: 1
}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));