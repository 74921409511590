import { toPercent } from '../../utils.js';

//1 badge
//2 plan
//3 pers
//4 2pack
//5 0nrp
//6 vid
//7 text

const position = [
	{height: 328, width: 423, left: 579, top: 376, },
	{height: 375, width: 598, left: 1242, top: 317, },
	{height: 328, width: 424, left: 108, top: 720, },
	{height: 328, width: 424, left: 579, top: 720, },
	{height: 328, width: 424, left: 1050, top: 720, },
	{height: 465, width: 668, left: 39, top: 45, },
	{height: 666, width: 1233, left: 435, top: 74, }]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));