
//1 scheme
//2 vid
//3 txt

import { toPercent } from '../../utils.js';

const position = 
	[{
		height: 513,
		width: 671,
		left: 67,
		top: 32,
	},{
		height: 385,
		width: 655,
		left: 411,
		top: 346
	},{
		height: 975,
		width: 856,
		left: 882,
		top: 38,
	}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));