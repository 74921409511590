/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect } from 'react';

import Screen1 from './Screen1'
import Screen2 from './Screen2'
import Screen3 from './Screen3'

import {ReactComponent as Logos} from '../../img/logos-intro.svg'
import {ReactComponent as Blur} from '../../img/blur.svg'

import blur from '../../img/blur.svg';


const Intro  = ({removeIntro}) => {
	const [endIdx, setEndIdx] = useState(0);
	
	return <>
		{endIdx === 0 && <Screen1 setEndIdx={setEndIdx}/>  }
		{endIdx < 3 && <Screen2 setEndIdx={setEndIdx} />  }
		{endIdx === 2 && <Screen3 setEndIdx={setEndIdx} removeIntro={removeIntro}/>  }
	</>
}

export default Intro;