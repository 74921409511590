import React from 'react';

import i1 from './img/1.png'
import i2 from './img/2.png'
import i3 from './img/3.png'
import i4 from './img/4.png'


import r1 from './img/r1.png'
import r2 from './img/r2.png'
import r3 from './img/r3.png'
import r4 from './img/r4.png'

import t1 from './img/kaserne-t1.svg'

import it1 from './img/kaserne.svg'

import { getMobileResearch } from '../../utils';

import title from '../../img/titles/kaserne.svg'

import positions from './research';

const img = [i1, i2, i3, i4]

const researchImgItems = [r1,r2,r3,r4];

const researchTextItems = [t1]

const researchImgItemsMap = researchImgItems.map((src, idx) => ({
	src,
	cl: `k-research rii-${idx+1}`,
	type: 'image'
}))


const researchTextItemsMap = researchTextItems.map((text, idx) => ({
	text,
	cl: `k-research research-text-item rti-${idx+1}`,
	type: 'text'
}))

const infoTexts = [
	{idx: 1, text: it1},]

const getText = index => {
	const info = infoTexts.find(item => item.idx === index);

	return info ? info.text : null;
}



const research = [...researchImgItemsMap, ...researchTextItemsMap].map((items, idx) => ({...items, style: positions[idx]}))
//const research = [researchImgItemsMap[0], researchImgItemsMap[1]]


export default {
	research,
	mobileResearch: getMobileResearch(
		researchTextItemsMap, 
		[], 
		researchImgItemsMap, 
		`r1, r2, r3, r4`, 
		`t1`, 
		null, 
		[`r2`, `t1`, `r4`, `r1`, `r3`]),
	title,
	route: "/germany/marburg/kaserne-barracks",
	gallery: img.map((img, i) => ({
		link: img,
		cl: `k-${i}`,
		text: getText(i)
	}))
}