import { toPercent } from '../../utils.js';

//1 dilemma
//2 iryna
//3 lustra
//4 girls
//5 class
//6 heart
//7 gr logo
//8 wh logo
//vid
//txt

const position = [
	{height: 273,
		width: 200,
		left: 89,
		top: 61,
		},
	{
		height: 275,
		width: 201,
		left: 89,
		top: 343,
	},{
		height: 154,
		width: 201,
		left: 90,
		top: 627,
	},{
		height: 739,
		width: 1088,
		left: 411,
		top: 17,
	},{
		height: 155,
		width: 201,
		left: 1571,
		top: 61,
	},{
		width: 202,
		height: 154,
		left: 1571,
		top: 275,
	},{
		width: 202,
		height: 154,
		left: 1571,
		top: 488,
	},{
		width: 201,
		height: 154,
		left: 1571,
		top: 701,
	},{
		width: 949,
		height: 550,
		left: 480,
		top: 483,
	},{
		height: 232,
		width: 573,
		left: 1222,
		top: 125,
	}
]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));

