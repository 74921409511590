//1 dbl plan
//2 gray pic
//3 2plan  down empty
//4 2plan  down full
//5 horizontal plan
//6 bright horizontal
//7 hor
//8 pic n plan
//9 4pic
//10 gray pic
//11 lamp
//12 front
//13 dark
//14 girl
//15 white front
//16 zabor vid
//17 a lrtt text
//18 th kh est txt





import { toPercent } from '../../utils.js';

const position = [{height: 363,
	width: 494,
	left: 21,
	top: 445,},{height: 370,
	width: 480,
	left: 453,
	top: 95,},{height: 212,
	width: 181,
	left: 334,
	top: 697,},{height: 212,
	width: 200,
	left: 468,
	top: 748,},{height: 207,
	width: 333,
	left: 565,
	top: 644,},{height: 158,
	width: 537,
	left: 247,
	top: 916,},{height: 152,
	width: 442,
	left: 836,
	top: 922,},{height: 305,
	width: 417,
	left: 1258,
	top: 769,},{height: 244,
	width: 331,
	left: 1366,
	top: 613,},{height: 216,
	width: 299,
	left: 1426,
	top: 417,},{height: 215,
	width: 299,
	left: 1571,
	top: 335,},{height: 216,
	width: 319,
	left: 1585,
	top: 417,},{height: 223,
	width: 311,
	left: 1280,
	top: 265,},{height: 531,
	width: 777,
	left: 764,
	top: 102,},{height: 147,
	width: 265,
	left: 423,
	top: 8,},{height: 386,
	width: 662,
	left: 764,
	top: 653,},{height: 240,
	width: 610,
	left: 1280,
	top: 51},{height: 999,
		width: 574,
		left: 21,
		top: 41,}]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));