import { toPercent } from '../../utils.js';

//1 person
//2 wall
//3 half bla
//4 street
//5 contr
//6 simp sq

const position = [
	{height: 822, width: 528, left: 32, top: 129, },
	{height: 569, width: 376, left: 594, top: 480, },
	{height: 373, width: 540, left: 869, top: 33, },
	{height: 519, width: 677, left: 753, top: 244, },
	{height: 250, width: 378, left: 1476, top: 250, },
	{height: 384, width: 446, left: 1447, top: 630, },
	{height: 435, width: 520, left: 340, top: 35,}
]

const frameSize = {width: 1920, height: 1080}

export default position.map(p => toPercent(p, frameSize));

